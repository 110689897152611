import "./HomeMain.scss";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useRef, useState } from "react";

import {
  changeThumbnail,
  deleteDesignSystem,
  exportJson,
  importJson,
  renameDesignSystem,
  duplicateDesignSystem,
} from "api";
import AuthAction from "components/auth/AuthAction";
import { useGlobalContext } from "context/GlobalContext";
import fileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import DesCardImage from "../../../assets/images/png/decCardImage.png";
import { CardSettingsIcon } from "../../Icons";
import ManageModal from "../FileUpload/ManageModal";
import RenameModal from "../FileUpload/RenameModal";
import ThumbnailModal from "../FileUpload/ThumbnailModal";
import { PERMISIONS } from "constants";
import Permission from "components/auth/Permission";
import { getUser } from "hooks/storageUtils";
import { getSubdomainHref } from "helpers";
import ConfirmDialog from "components/Common/Confirm/ConfirmDialog";

const HomeMain = (props) => {
  const { designSystems, setDesignSystems } = props; // Extracting designSystems from props

  useEffect(() => {
    // Remove the 'tokens' item from localStorage
    localStorage.removeItem("tokens");
  }, []);

  let inputFile = useRef([]);
  const { setLoading } = useGlobalContext();

  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isManageModalOpen, setManageModalOpen] = useState(false);
  const [isThumbnailModalOpen, setThumbnailModalOpen] = useState(false);
  const [selectedDesignSystem, setSelectedDesignSystem] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [error, setError] = useState();

  function toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      setUserName(user.name);
    }
  }, []);

  const [openMenuId, setOpenMenuId] = useState(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.closest(".menu")) {
        setOpenMenuId(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleChange = async (e, adminId, id) => {
    try {
      const request = {
        json: e.target.files[0],
        admin_id: adminId,
        design_system_id: id,
      };
      setLoading(true);
      const data = await importJson(request);
      toast.success(data.message, {
        onClose: () => {},
        autoClose: 1000,
      });
    } catch (error) {
      console.error("Failed to export design system", error);
      let errorMessage = "";
      errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message || "An unexpected error occurred";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  //import
  const handleImport = (index) => {
    if (inputFile.current[index]) {
      inputFile.current[index].click();
    }
  };

  // Export
  const handleExport = async (adminId, designSystemId) => {
    try {
      const data = await exportJson(adminId, designSystemId);
      fileDownload(JSON.stringify(data), "export.json");
    } catch (error) {
      console.error("Failed to export design system", error);
    }
  };

  const closeRenameModal = () => {
    setRenameModalOpen(false);
  };

  const closeManageModal = () => {
    setManageModalOpen(false);
  };
  const closeThumbnailModal = () => {
    setThumbnailModalOpen(false);
  };

  const handleRenameApply = async (name) => {
    if (!name) {
      toast.warning("Please enter name");
      return;
    }
    try {
      setLoading(true);
      const data = await renameDesignSystem(selectedDesignSystem.id, {
        design_system_name: name,
      });
      toast.success(data.message);
      setSelectedDesignSystem({
        ...selectedDesignSystem,
        design_system_name: name,
      });
      updateState(name);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to rename the design system", error);
    } finally {
      setLoading(false);
    }
    closeRenameModal();
  };

  const handleThumbnailApply = async (file) => {
    if (!file) {
      setError("Please select an image");
      return;
    }
    try {
      setLoading(true);

      const request = {
        image: file,
        admin_id: selectedDesignSystem.admin_id,
        design_system_id: selectedDesignSystem.id,
        type: selectedType,
      };
      const data = await changeThumbnail(selectedDesignSystem.id, request);
      toast.success(data.message, {
        onClose: () => {},
        autoClose: 1000,
      });
      updateStateWithThumbnail(data.data, selectedType);
    } catch (error) {
      console.error("Failed to change thumbnail", error);
      let errorMessage = "";
      errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message || "An unexpected error occurred";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
    closeThumbnailModal();
  };
  const updateState = (name) => {
    const newDesignSystems = [...designSystems];
    const obj = newDesignSystems.find(
      (ds) => ds.id === selectedDesignSystem.id
    );
    obj.design_system_name = name;
    setDesignSystems(newDesignSystems);
  };
  const updateStateWithThumbnail = (data, type) => {
    const newDesignSystems = [...designSystems];
    const obj = newDesignSystems.find(
      (ds) => ds.id === selectedDesignSystem.id
    );
    if (type == "thumbnail") {
      obj.image = data.image;
      obj.image_url = data.image_url;
    } else if (type == "logo") {
      obj.logo = data.logo;
      obj.logo_url = data.logo_url;
    }
    setDesignSystems(newDesignSystems);
  };

  const deleteState = (id) => {
    const newDesignSystems = [...designSystems];
    const filtered = newDesignSystems.filter((ds) => ds.id !== id);
    setDesignSystems(filtered);
  };

  const [isDeleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const closeDeleteConfirmModal = () => {
    setDeleteConfirmModalOpen(false);
  };

  const handleDelete = async (id) => {
    // if (confirm("Are you sure to delete") == true) {
    try {
      setLoading(true);
      const data = await deleteDesignSystem(id);
      toast.success(data.message);
      deleteState(id);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to delete the design system", error);
    } finally {
      setLoading(false);
      setDeleteConfirmModalOpen(false);
    }
    // }
  };

  const [isDuplicateConfirmModalOpen, setDuplicateConfirmModalOpen] =
    useState(false);
  const closeDuplicateConfirmModal = () => {
    setDuplicateConfirmModalOpen(false);
  };

  const handleDuplicate = async (id) => {
    try {
      setLoading(true);
      const data = await duplicateDesignSystem(id);
      if (data.upgrade) {
        toast.warning(data.message);
      } else {
        toast.success(data.message);
        setDesignSystems((prevState) => [...prevState, data.data]);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to duplicate the design system", error);
    } finally {
      setLoading(false);
      setDuplicateConfirmModalOpen(false);
    }
  };

  const designSystemDomain = (designSystemId, domainName) => {
    const currentUrl = window.location.origin;
    const user = getUser();
    const token = localStorage.getItem("token");
    // Check if the current base URL is different from the target domain (excluding paths)
    if (domainName && process.env.REACT_APP_ENV === "production") {
      const subdomainUrl = getSubdomainHref(domainName);
      window.location.href = `${subdomainUrl}/${designSystemId}/overview/getting-started?token=${token}&userId=${user?.id}`;
    } else {
      navigate(`/${designSystemId}/overview/getting-started`);
    }
  };

  return (
    <div className='home w-full d-flex justify-content-between dis-pl-24 dis-pr-24'>
      <ToastContainer />
      <div className='home-wrapper w-full dis-pl-86 dis-mt-20 dis-mb-32'>
        <h3 className='home-title plt-heading-3xl plt-font-color-primary dis-mb-12'>
          Welcome, {userName}!
        </h3>
        <p className='home-title-des plt-body-md'>
          The default mode you select here can help you choose colors and
          components that go well together.
        </p>
        <div className='plt-card-container d-flex dis-mt-54'>
          {designSystems.length ? (
            designSystems.map((designSystem, index) => (
              <div
                key={designSystem.id}
                className={`plt-card  ${
                  openMenuId === designSystem.id ? "active" : ""
                }`}
              >
                <div
                  onClick={() =>
                    designSystemDomain(
                      designSystem.id,
                      designSystem?.domain?.domain_name
                    )
                  }
                  className='plt-card-image cursor-pointer'
                >
                  <img
                    src={
                      designSystem.image
                        ? `${process.env.REACT_APP_SHEET_URL}${designSystem.image_url}`
                        : DesCardImage
                    }
                    alt='design system thumbnail'
                  />
                </div>
                <div className='plt-card-body d-flex dis-mt-16 justify-content-between'>
                  <div className='plt-card-body-wrapper'>
                    <h6 className='plt-card-title plt-heading-xxs plt-font-color-primary dis-mb-8'>
                      {designSystem.design_system_name}
                    </h6>
                    <span className='plt-card-desc plt-font-color-tertiary plt-body-xs'>
                      Last modified on {designSystem.updated_at}
                    </span>
                  </div>
                  <Permission designSystem={designSystem}>
                    <div className='menu cursor-pointer'>
                      <span
                        onClick={() =>
                          setOpenMenuId(
                            openMenuId === designSystem.id
                              ? null
                              : designSystem.id
                          )
                        }
                      >
                        <CardSettingsIcon />
                      </span>
                      {openMenuId === designSystem.id && (
                        <div className='dropdown-menu '>
                          <ul>
                            <AuthAction>
                              <Permission designSystemId={designSystem.id}>
                                <li
                                  onClick={() => {
                                    setOpenMenuId(null);
                                    setRenameModalOpen(true);
                                    setSelectedType("");
                                    setSelectedDesignSystem(designSystem);
                                  }}
                                  className='plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                                >
                                  Rename
                                </li>
                              </Permission>
                            </AuthAction>
                            <AuthAction>
                              <li
                                onClick={() => {
                                  setOpenMenuId(null);
                                  setThumbnailModalOpen(true);
                                  setSelectedType("thumbnail");
                                  setSelectedDesignSystem(designSystem);
                                }}
                                className='plt-ui-text-sm-medium plt-font-color-primary'
                              >
                                Change thumbnail
                              </li>
                            </AuthAction>
                            {/* <AuthAction>
                            <li
                              onClick={() => {
                                setOpenMenuId(null);
                                setManageModalOpen(true);
                                setSelectedType("Manage");
                                setSelectedDesignSystem(designSystem);
                              }}
                              className='plt-ui-text-sm-medium plt-font-color-primary'
                            >
                              Manage access
                            </li>
                          </AuthAction> */}
                            <AuthAction>
                              <li
                                className='plt-ui-text-sm-medium plt-font-color-primary'
                                onClick={() => inputFile.current[index].click()}
                              >
                                Import JSON
                              </li>
                              <input
                                name={"json" + designSystem.id}
                                type='file'
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    designSystem.admin_id,
                                    designSystem.id
                                  )
                                }
                                ref={(el) => (inputFile.current[index] = el)}
                                style={{ display: "none" }}
                              />
                            </AuthAction>

                            <li
                              className='plt-ui-text-sm-medium plt-font-color-primary'
                              onClick={() => {
                                handleExport(
                                  designSystem.admin_id,
                                  designSystem.id
                                );
                                setOpenMenuId(null);
                              }}
                            >
                              Export JSON
                            </li>
                            <AuthAction>
                              <li
                                className='plt-ui-text-sm-medium plt-font-color-primary'
                                // onClick={() => {
                                //   handleDelete(designSystem.id);
                                //   setOpenMenuId(null);
                                // }}
                                onClick={() => {
                                  setSelectedDesignSystem(designSystem);
                                  setDuplicateConfirmModalOpen(true);
                                }}
                              >
                                Duplicate
                              </li>
                            </AuthAction>
                            <AuthAction>
                              <li
                                className='plt-ui-text-sm-medium plt-font-color-primary'
                                // onClick={() => {
                                //   handleDelete(designSystem.id);
                                //   setOpenMenuId(null);
                                // }}
                                onClick={() => {
                                  setSelectedDesignSystem(designSystem);
                                  setDeleteConfirmModalOpen(true);
                                }}
                              >
                                Delete
                              </li>
                            </AuthAction>
                          </ul>
                        </div>
                      )}
                    </div>
                  </Permission>
                </div>
              </div>
            ))
          ) : (
            <div className='plt-heading-lg'>
              Start by creating a new design system
            </div>
          )}
        </div>
      </div>
      <RenameModal
        isOpen={isRenameModalOpen}
        onRequestClose={closeRenameModal}
        onApply={handleRenameApply}
        title='Rename design system'
        data={selectedDesignSystem}
      />
      <ManageModal
        isOpen={isManageModalOpen}
        // onRequestClose={closeManageModal}
        // onApply={handleManageApply}
        // title='Manage design system'
        onRequestClose={() => setManageModalOpen(false)}
        title='Manage access'
        onApply={() => setManageModalOpen(false)}
        data={selectedDesignSystem}
        permissions={PERMISIONS}
      />
      <ThumbnailModal
        selectedType={selectedType}
        isOpen={isThumbnailModalOpen}
        onRequestClose={closeThumbnailModal}
        onApply={handleThumbnailApply}
        title={`Change ${selectedType}`}
        data={selectedDesignSystem}
        aspectRatio={261 / 177}
        error={error}
      />
      <ConfirmDialog
        footerClassName='plt'
        isOpen={isDeleteConfirmModalOpen}
        onRequestClose={closeDeleteConfirmModal}
        onApply={() => handleDelete(selectedDesignSystem?.id)}
        title='Delete design system?'
        message='The design system you have created will be removed.'
      />
      <ConfirmDialog
        footerClassName='plt'
        isOpen={isDuplicateConfirmModalOpen}
        onRequestClose={closeDuplicateConfirmModal}
        onApply={() => handleDuplicate(selectedDesignSystem?.id)}
        onApplyText='Duplicate'
        title='Duplicate design system?'
        message='The design system will be duplicated'
      />
    </div>
  );
};

export default HomeMain;
