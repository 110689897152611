import React, { useState, useEffect, useCallback } from "react";
import "./EditHeadingModal.scss";
import CommonModal from "components/Common/CommonModal";
import { toast } from "react-toastify";
import SelectInput from "components/SelectInput";

const AddFontModal = ({ isOpen, onRequestClose, onApply, title }) => {
  const [selectedFont, setSelectedFont] = useState();
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [variants, setVariants] = useState([]);
  const [fonts, setFonts] = useState([]);
  const [fontDropdown, setFontDropdown] = useState([]);

  const apiKey = process.env.REACT_APP_GOOGLE_FONT_API_KEY;

  useEffect(() => {
    fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`)
      .then((response) => response.json())
      .then((data) => {
        setFontDropdown(
          data.items.map((font) => {
            return { value: font.family, label: font.family };
          })
        );
        setFonts(data.items);
      })
      .catch((error) => console.error("Error fetching Google Fonts:", error));
  }, []);

  const handleFontChange = (selectedFont) => {
    const fontObj = fonts.find((font) => font.family === selectedFont.value);
    setSelectedFont(fontObj);
    setVariants(
      fontObj?.variants?.map((variant) => {
        return { value: variant, label: variant };
      })
    );
  };

  const handleVariantChange = (value) => {
    setSelectedVariant(value);
  };

  const resetForm = () => {
    setSelectedFont("");
    setSelectedVariant([]);
  };
  const handleApply = () => {
    if (!selectedVariant) {
      toast.warning("Please select font family");
      return;
    }
    if (!selectedVariant.length) {
      toast.warning("Please select variant");
      return;
    }
    let font = fonts.find((font) => font.family === selectedFont.family);
    let fontUrl = font?.files[selectedVariant[0]?.value];
    let data = {
      family: font.family,
      variant: selectedVariant?.map(({ value }) => value).toString(),
      url: fontUrl,
    };
    if (!data.url) {
      toast.warning("Please select variant");
      return;
    }

    onApply(data);
    resetForm();
    onRequestClose();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      onApply={handleApply}
      onCancelText='Cancel'
      onApplyText='Add'
      // bodyClassName='overflow-unset'
    >
      {/* <div className='plt-dropdown-secondary '>
        <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
          <div className='d-flex justify-content-center  align-items-center dis-p-20'>
            <span>Font</span>
            <SelectInput
              options={fontDropdown}
              selectedOption={selectedFont ?? "Select font..."}
              onSelect={(selectedValue) => handleFontChange(selectedValue)}
            />
          </div>
          <div className='d-flex justify-content-center  align-items-center '>
            <span>Variant</span>
            <SelectInput
              multi={true}
              options={variants}
              selectedOption={selectedVariant ?? "Select variant..."}
              onSelect={(selectedValue) => handleVariantChange(selectedValue)}
            />
          </div>
          
        </div>
      </div> */}
      <div className='plt-modal-body-content'>
        <div className='plt-dropdown-secondary plt-select-dropdown-conatiner d-flex align-items-center justify-content-between dis-pl-24 dis-pr-24 dis-pt-16 dis-pb-16'>
          <div className='plt-select-dropdown-wrapper'>
            <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Font
            </h6>
            <div className='plt-multi-dropdown-wrapper'>
              <SelectInput
                options={fontDropdown}
                selectedOption={selectedFont ?? "Select font..."}
                onSelect={(selectedValue) => handleFontChange(selectedValue)}
              />
            </div>
          </div>
          <div className='plt-select-dropdown-wrapper'>
            <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Variant
            </h6>
            <div className='plt-multi-dropdown-wrapper'>
              <SelectInput
                multi={true}
                options={variants}
                selectedOption={selectedVariant ?? "Select variant..."}
                onSelect={(selectedValue) => handleVariantChange(selectedValue)}
              />
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default AddFontModal;
