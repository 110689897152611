import React, { useEffect, useRef, useState } from "react";

import {
  upgradeSubscription,
  renewSubscription,
  cancelSubscription,
} from "api/superAdmin";
import { CardSettingsIcon } from "components/Icons";
import { useGlobalContext } from "context/GlobalContext";
import ConfirmDialog from "components/Common/Confirm/ConfirmDialog";

const SubscriptionTableRow = ({ row, slno, setSubscriptions }) => {
  const dropdownRef = useRef(null);
  const { setLoading } = useGlobalContext();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isRenewConfirmModalOpen, setRenewConfirmModalOpen] = useState(false);
  const [isUpgradeConfirmModalOpen, setUpgradeConfirmModalOpen] =
    useState(false);
  const [isCancelConfirmModalOpen, setCancelConfirmModalOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const updateState = (data) => {
    setSubscriptions((prevState) =>
      prevState.map((subscription) => {
        if (subscription.id !== data.id) {
          return subscription;
        } else {
          return {
            ...subscription,
            plan: data.plan,
            starts_at: data.starts_at,
            ends_at: data.ends_at,
          };
        }
      })
    );
  };

  const handleUpgradeSubscription = async () => {
    try {
      setLoading(true);
      const { data } = await upgradeSubscription(row.userId, "premium");
      updateState(data);
    } catch (error) {
      console.error("Failed to upgrade subscription", error);
    } finally {
      setLoading(false);
      setUpgradeConfirmModalOpen(false);
    }
  };
  const handleRenewSubscription = async () => {
    try {
      setLoading(true);
      const { data } = await renewSubscription(row.userId, row.id);
      updateState(data);
    } catch (error) {
      console.error("Failed to renew subscription", error);
    } finally {
      setLoading(false);
      setRenewConfirmModalOpen(false);
    }
  };
  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      const { data } = await cancelSubscription(row.userId, row.id);
      updateState(data);
    } catch (error) {
      console.error("Failed to renew subscription", error);
    } finally {
      setLoading(false);
    }
  };

  const closeRenewConfirmModal = () => {
    setRenewConfirmModalOpen(false);
  };
  const closeUpgradeConfirmModal = () => {
    setUpgradeConfirmModalOpen(false);
  };
  const closeCancelConfirmModal = () => {
    setCancelConfirmModalOpen(false);
  };

  return (
    <tr>
      <td>
        <div className='plt-color-token-name d-flex align-items-center'>
          <p className='plt-color-description plt-body-md plt-font-color-secondary'>
            {slno}
          </p>
          <div className='plt-table-hover-icon dis-ml-8'></div>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center plt-body-md'>
          {/* <img
            src={userAvatar}
            alt='profile'
            className='user-image dis-mr-12'
          /> */}
          {row?.email}
        </div>
      </td>
      <td>
        <p className='plt-role-description plt-body-md plt-font-color-secondary'>
          {row?.plan}
        </p>
      </td>
      <td>
        <div className='plt-asccess-description  plt-body-md plt-font-color-secondary'>
          {" "}
          {row?.starts_at}
        </div>
      </td>
      <td>
        <div className='d-flex'>
          <div className='plt-color-detail plt-body-md'>{row?.ends_at} </div>
        </div>
      </td>

      <td>
        <div className='menu cursor-pointer'>{row.status}</div>
      </td>
      <td>
        <div className='menu cursor-pointer'>
          <div
            className='plt-select-dropdown-wrapper plt-filter-dropdown'
            ref={dropdownRef}
          >
            <span onClick={() => setIsDropdownOpen((prev) => !prev)}>
              <CardSettingsIcon />
            </span>
            {isDropdownOpen && (
              <div className='user-dropdown'>
                <ul>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => {
                      setRenewConfirmModalOpen(true);
                    }}
                  >
                    Renew
                  </li>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => setUpgradeConfirmModalOpen(true)}
                  >
                    Upgrade subscription
                  </li>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => setCancelConfirmModalOpen(true)}
                  >
                    Cancel subscription
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </td>
      <ConfirmDialog
        footerClassName='plt'
        isOpen={isRenewConfirmModalOpen}
        onRequestClose={closeRenewConfirmModal}
        onApply={handleRenewSubscription}
        onApplyText='Renew'
        title='Renew subscription'
        message='Are you sure to renew ?'
      />
      <ConfirmDialog
        footerClassName='plt'
        isOpen={isCancelConfirmModalOpen}
        onRequestClose={closeCancelConfirmModal}
        onApply={handleCancelSubscription}
        onApplyText='Cancel'
        title='Cancel subscription'
        message='Are you sure to cancel ?'
      />
      <ConfirmDialog
        footerClassName='plt'
        isOpen={isUpgradeConfirmModalOpen}
        onRequestClose={closeUpgradeConfirmModal}
        onApply={handleUpgradeSubscription}
        onApplyText='Upgrade'
        title='Upgrade subscription'
        message='Are you sure to upgrade ?'
      />
    </tr>
  );
};

export default SubscriptionTableRow;
