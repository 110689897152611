import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

//Fetch token team memeber
async function fetchPlans() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/plans`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch plans:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export { fetchPlans };
