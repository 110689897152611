import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

// Upgrade subscription
async function upgradeSubscription(userId, plan) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/super-admin/subscriptions/change`,
      { userId, plan },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in upgrade subscription:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

// Renew subscription
async function renewSubscription(userId, plan) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/super-admin/subscriptions/renew`,
      { plan, userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in renew subscription:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
// Cancel subscription
async function cancelSubscription(userId, plan) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/super-admin/subscriptions/cancel`,
      { plan, userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in renew subscription:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Fetch token team memeber
async function fetchInvoices() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/super-admin/all-invoices`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch subscriptions:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export {
  upgradeSubscription,
  renewSubscription,
  cancelSubscription,
  fetchInvoices,
};
