import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

// Create member
async function addMember(data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/invites`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in create invite:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Fetch token team memeber
async function fetchTeamMembers(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/team-memebers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch team memebers:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

// Delete design system
async function deleteMember(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.delete(`${apiUrl}/invites/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in delete memeber:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Fetch invite
async function fetchInvite(inviteToken) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/invites/${inviteToken}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch team memebers:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//update team permission
async function updateMember(id, data, role) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      `${apiUrl}/invites/${id}`,
      { permission: data, role },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in update member:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

/**
 * Check email already invited
 */
async function checkInvited(data, adminId) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/check-invites`,
      { data, admin_id: adminId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in check invites:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Check invite
async function checkJoinInvite(inviteToken) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/check-join-invite/${inviteToken}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in check join invite:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

/**
 * Join invite for existing user
 */
async function joinInvite(inviteId) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/join-invite/${inviteId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in check invites:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
async function fetchSettingsDesignSystems(admin_id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/settings/design-systems/${admin_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

/**
 * Update design system domain name
 */
async function updateDesignSystemDomainName(id, name) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      `${apiUrl}/design-system-domains/${id}`,
      { domain_name: name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in update design system domain name:", error);
    throw new Error(
      error.response && error.response.data.errors
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}

// Manage access in settings design system
async function manageAccess(data, designSystemId) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/manage-access/${designSystemId}`,
      { permission: data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in update member:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
// Manage access in settings design system
async function designSystemManageAccess(data, designSystemId) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/design-system-manage-access/${designSystemId}`,
      { permission: data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in manage access:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
async function addDesignSystem(adminId, request) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/setting/design-system/add?admin_id=${adminId}`,
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export {
  addMember,
  fetchTeamMembers,
  fetchInvite,
  deleteMember,
  updateMember,
  checkInvited,
  checkJoinInvite,
  joinInvite,
  fetchSettingsDesignSystems,
  updateDesignSystemDomainName,
  manageAccess,
  designSystemManageAccess,
  addDesignSystem,
};
