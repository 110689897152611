import React, { useState } from "react";
import "./ElevationUsage.scss";
import elevationImg from "../../../../assets/images/png/elevationImg.png";
import elevationImgRaised from "../../../../assets/images/png/elevationImgRaised.png";
import elevationImgHover from "../../../../assets/images/png/elevationImgHover.png";
import elevationImgOverlay from "../../../../assets/images/png/elevationImgOverlay.png";
import elevationImgPressed from "../../../../assets/images/png/elevationImgPressed.png";
import TabNavigation from "../../TabNavigation/index";
import AddButton from "../../../Buttons/AddButton";
import DeleteButton from "../../../Buttons/DeleteButton";
import EditButton from "../../../Buttons/EditButton";
import { ElevationUsageDatas } from "../../../../assets/data/data";
import UsageCardImg from "../../../Common/UsageCardImg";
import useScrollManagement from "hooks/useScrollManagement";


const ElevationUsage = () => {
  const tabData = [
    "Why use elevation?", "Content guidelines"];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };


  const sectionRefs = React.useRef([React.createRef(), React.createRef()]);

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeTab,
    setSelectedTab,
    "tab-content-body"
  );


  const handleTabClick = (index) => {
    setActiveTab(index);
    scrollToSection(index);
  };


  return (
    <div className="d-flex justify-content-between w-full">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        <div className="plt-content-block dis-mb-32">
          <div className="plt-content-wrapper">
            <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
              <h6 ref={sectionRefs.current[0]}
                className="plt-contents-head plt-heading-lg plt-font-color-primary">
                Why use elevation?
              </h6>
            </div>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-8">
              Elevations contribute to the visual hierarchy and spatial
              relationships between components.
            </p>
          </div>
        </div>

        <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-24">
          Application
        </h6>

        <div className="plt-content-block dis-mb-0">
          <div className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">
                Default
              </h6>
              {/* <div className="plt-icon-wrapper d-flex justify-content-between align-items-center">
                <span>
                  <AddButton />
                </span>
                <span>
                  <DeleteButton />
                </span>
                <span>
                  <EditButton />
                </span>
              </div> */}
            </div>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-8">
              This color is primary and is used for main elements and
              background.
            </p>
            <h5 className="plt-badge plt-body-compact-sm font-color-black">
              shadow-0
            </h5>
          </div>
        </div>

        <div className="plt-common-card-wrapper d-flex dis-mb-48">
          <div className="plt-application-card">
            <div className="plt-application-card-wrapper">
              <h3 className="plt-common-card-badge font-color-black">
                shadow-0
              </h3>
              <div className="usage-img dis-ml-40">
                <img src={elevationImg} alt="userimage" />
              </div>
            </div>
          </div>
        </div>

        <div className="plt-content-block dis-mb-0">
          <div className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">
                Raised
              </h6>
            </div>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-8">
              This color is primary and is used for main elements and
              background.
            </p>
            <h5 className="plt-badge plt-body-compact-sm font-color-black">
              shadow-100
            </h5>
          </div>
        </div>

        <div className="plt-common-card-wrapper d-flex dis-mb-48">
          <div className="plt-application-card">
            <div className="plt-application-card-wrapper">
              <h3 className="plt-common-card-badge font-color-black">
                shadow-100
              </h3>
              <div className="usage-img dis-ml-40">
                <img src={elevationImgRaised} alt="userimage" />
              </div>
            </div>
          </div>
        </div>

        <div className="plt-content-block dis-mb-0">
          <div className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">
                Hover/ Focus
              </h6>
            </div>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-8">
              This color is primary and is used for main elements and
              background.
            </p>
            <h5 className="plt-badge plt-body-compact-sm font-color-black">
              shadow-300
            </h5>
          </div>
        </div>

        <div className="plt-common-card-wrapper d-flex dis-mb-48">
          <div className="plt-application-card">
            <div className="plt-application-card-wrapper">
              <h3 className="plt-common-card-badge font-color-black">
                shadow-300
              </h3>
              <div className="usage-img dis-ml-40">
                <img src={elevationImgHover} alt="userimage" />
              </div>
            </div>
          </div>
        </div>

        <div className="plt-content-block dis-mb-0">
          <div className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">
                Overlays
              </h6>
            </div>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-8">
              This color is primary and is used for main elements and
              background.
            </p>
            <h5 className="plt-badge plt-body-compact-sm font-color-black">
              shadow-600
            </h5>
          </div>
        </div>

        <div className="plt-common-card-wrapper d-flex dis-mb-48">
          <div className="plt-application-card">
            <div className="plt-application-card-wrapper">
              <h3 className="plt-common-card-badge font-color-black">
                shadow-600
              </h3>
              <div className="usage-img dis-ml-40">
                <img src={elevationImgOverlay} alt="userimage" />
              </div>
            </div>
          </div>
        </div>

        <div className="plt-content-block dis-mb-0">
          <div className="plt-content-wrapper dis-mb-24">
            <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">
                Pressed
              </h6>
            </div>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-8">
              This color is primary and is used for main elements and
              background.
            </p>
            <h5 className="plt-badge plt-body-compact-sm font-color-black">
              shadow-fill-pressed-large
            </h5>
          </div>
        </div>

        <div className="plt-common-card-wrapper d-flex dis-mb-48">
          <div className="plt-application-card">
            <div className="plt-application-card-wrapper">
              <h3 className="plt-common-card-badge font-color-black">
                shadow-fill-pressed-large
              </h3>
              <div className="usage-img dis-ml-40">
                <img src={elevationImgPressed} alt="userimage" />
              </div>
            </div>
          </div>
        </div>

        <div className="plt-common-card-wrapper d-flex">
          <div className="plt-content-block  dis-mb-0">
            <div className="plt-content-wrapper">
              <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
                <h6 ref={sectionRefs.current[1]}
                  className="plt-contents-head plt-heading-md plt-font-color-primary">
                  Content guidelines
                </h6>
              </div>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-pb-4">
                Buttons are fundamental elements in user interfaces, serving as
                interactive components that facilitate user actions and
                engagement.
              </p>
            </div>
          </div>
          <div className="plt-content-block  dis-mb-0">
            <div className="plt-content-wrapper">
              <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
                <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">
                  Use sentence case capitalization
                </h6>
              </div>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-pb-4">
                Only capitalize the first letter of the button and any proper
                nouns. Most feature names aren’t capitalized or considered
                proper nouns when following our capitalization guidance.
              </p>
            </div>
          </div>

          <div className="plt-usage-card-wrapper d-flex justify-content-between">
            {ElevationUsageDatas.map((data) => (
              // <div key={data.id} className="plt-common-card">
              <UsageCardImg UsageBoxData={data} key={data.id} />
            ))}
          </div>
        </div>
      </div>
      <div className="plt-token-contents ">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default ElevationUsage;
