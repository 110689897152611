import axios from "axios";
import {
  getAdminId,
  addToLocalStorage,
  getDomainAdminId,
} from "../hooks/storageUtils";
import {
  fetchUserPermissions,
  manageUserPermission,
  fetchRoles,
  checkFeaturePermission,
  fetchFeatureValueAndUsage,
} from "./permission";

import {
  login,
  register,
  inviteRegister,
  forgetPassword,
  fetchPasswordAudit,
  updateUser,
  fetchUserSubscription,
  changePassword,
  verifyOtp,
  resetPassword,
  uploadAvatar,
  deleteUser,
  updateGeneralSettings,
  switchDomain,
} from "./auth";

import { downloadInvoice } from "./invoice";
import {
  fetchPage,
  createPage,
  updatePage,
  renamePage,
  deletePage,
} from "./page";
import { createFont, uploadFont } from "./font";
import {
  addMember,
  fetchTeamMembers,
  fetchInvite,
  deleteMember,
  updateMember,
  checkInvited,
  checkJoinInvite,
  joinInvite,
  fetchSettingsDesignSystems,
  updateDesignSystemDomainName,
  manageAccess,
  designSystemManageAccess,
  addDesignSystem,
} from "./team";

import {
  upgradeSubscription,
  fetchSubscriptions,
  renewSubscription,
  cancelSubscription,
} from "./subscription";
import { fetchPlans } from "./plan";

import { sendContactUsMail } from "./contact";

const adminId = getAdminId();
const apiUrl = process.env.REACT_APP_API_URL;

const token = localStorage.getItem("token");

async function createDesignSystem(adminId, request) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/create-design-system?admin_id=${adminId}`,
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function fetchDesignSystem(adminId, stateToken) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/fetch-design-systems/admin_id=${adminId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token || stateToken}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function fetchTokens(id, design_system_id, type) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/design-tokens-sorting/?admin_id=${id}&design_system_id=${design_system_id}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token || stateToken}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function fetchDesignSystemById(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/fetch-design-system/id=${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//token update
async function updateToken(tokens, id) {
  try {
    const token = localStorage.getItem("token");
    const adminId = getDomainAdminId();
    const response = await axios.post(
      `${apiUrl}/design-tokens/admin_id=${adminId}/design_system_id=${id}`,
      tokens,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Store to local storage
    addToLocalStorage(tokens);

    return response.data;
  } catch (error) {
    console.error("Error in updateToken:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Fetch ui text data
async function fetchUiText(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/uitext-tokens/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in updateToken:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

/**
 * Fetch variable css file
 * @param {string} id
 * @returns file
 */
async function fetchVariables(adminId, designSystemId) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/variables/${adminId}/${designSystemId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetch variables css:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

/**
 * Fetch variable css file
 * @param {string} id
 * @returns file
 */
async function importJson(data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/import`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch variables css:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

/**
 * Fetch variable css file
 * @param {string} id
 * @returns file
 */
async function exportJson(adminId, designSystemId) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/export?admin_id=${adminId}&design_system_id=${designSystemId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetch variables css:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Fetch token dropdown data
async function tokenDropdown(id, type) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/token-dropdown?design_system_id=${id}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in updateToken:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
//Fetch token dropdown data
async function uiTextTokens(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/uitext-tokens/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in updateToken:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Rename design system
async function renameDesignSystem(id, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(`${apiUrl}/design-systems/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in rename the design system:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Change thumbnail
async function changeThumbnail(id, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/update-image/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in change thumbnail:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

// Delete design system
async function deleteDesignSystem(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      `${apiUrl}/delete-design-systems/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in delete design system:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

// Upload company logo
async function uploadLogo(id, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/upload-logo/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in upload logo:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function updateDescription(id, description) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/design-tokens/update-description/${id}`,
      { description },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}

async function deleteImage(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/delete-image/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response);
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}
async function deleteDesignSystemImage(id, type) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/design-system/delete-image/${id}`,
      { type },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response);
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}

//Rename design system
async function updateDesignSystemType(id, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      `${apiUrl}/design-systems/update-type/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in rename the design system:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

/**
 * Copy design system
 */
async function duplicateDesignSystem(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/design-system/duplicate/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response);
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}

async function fetchDesignSystemBySubdmain(subdomain) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/design-system-domain/${subdomain}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export {
  login,
  fetchPage,
  register,
  updatePage,
  forgetPassword,
  createPage,
  createDesignSystem,
  fetchDesignSystem,
  fetchDesignSystemById,
  updateToken,
  fetchTokens,
  fetchVariables,
  importJson,
  exportJson,
  fetchUiText,
  tokenDropdown,
  uiTextTokens,
  createFont,
  renameDesignSystem,
  changeThumbnail,
  uploadFont,
  deleteDesignSystem,
  uploadAvatar,
  deleteUser,
  uploadLogo,
  addMember,
  fetchTeamMembers,
  fetchInvite,
  deleteMember,
  inviteRegister,
  changePassword,
  fetchUserPermissions,
  manageUserPermission,
  fetchRoles,
  verifyOtp,
  resetPassword,
  updateDescription,
  deleteImage,
  fetchPasswordAudit,
  updateUser,
  fetchUserSubscription,
  downloadInvoice,
  updateGeneralSettings,
  updateMember,
  deleteDesignSystemImage,
  renamePage,
  deletePage,
  switchDomain,
  checkInvited,
  checkJoinInvite,
  joinInvite,
  fetchSettingsDesignSystems,
  updateDesignSystemDomainName,
  updateDesignSystemType,
  manageAccess,
  designSystemManageAccess,
  checkFeaturePermission,
  upgradeSubscription,
  fetchSubscriptions,
  renewSubscription,
  cancelSubscription,
  fetchPlans,
  duplicateDesignSystem,
  addDesignSystem,
  fetchFeatureValueAndUsage,
  fetchDesignSystemBySubdmain,
  sendContactUsMail,
};
