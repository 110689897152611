import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

// Upgrade subscription
async function upgradeSubscription(plan) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/subscriptions/change`,
      { plan },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in upgrade subscription:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Fetch token team memeber
async function fetchSubscriptions(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/all-subscriptions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch subscriptions:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

// Renew subscription
async function renewSubscription(plan) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/subscriptions/renew`,
      { plan },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in renew subscription:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
// Cancel subscription
async function cancelSubscription(plan) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/subscriptions/renew`,
      { plan },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in renew subscription:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export {
  upgradeSubscription,
  fetchSubscriptions,
  renewSubscription,
  cancelSubscription,
};
