import React, { useState, useRef, useEffect } from "react";
import SettingsLayout from "components/layouts/SettingsLayout";
import DesignsystemsTable from "./DesignsystemsTable";
import { useGlobalContext } from "context/GlobalContext";
import { fetchRoles } from "api";
import { FilterButtonicon, PlusButtonWhiteicon } from "components/Icons";
import "./Designsystems.scss";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { fetchSettingsDesignSystems } from "api";
import { getAdminId } from "hooks/storageUtils";
import { checkFeaturePermission } from "api";
import CreateDesignSystemModal from "components/modal/CreateDesignSystemModal";
import UpgradePlanAlertModal from "components/modal/UpgradePlanAlertModal";
import { PremiumIcon } from "components/Icons";
import { addDesignSystem } from "api";
import { toast } from "react-toastify";
import { fetchFeatureValueAndUsage } from "api";
import CircularLoader from "components/Common/CircularLoader/CircularLoader";

const Designsystems = () => {
  const dropdownRef = useRef(null);
  const { setLoading } = useGlobalContext();

  const [designSystems, setDesignSystems] = useState([]);
  const [valueAndUsage, setValueAndUsage] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [canCreateDesignSystem, setCanCreateDesignSystem] = useState(false);
  const [isCreateDSModalOpen, setCreateDSModalOpen] = useState(false);
  const [isUpgradePlanAlertModalOpen, setUpgradePlanAlertModalOpen] =
    useState(false);

  useEffect(() => {
    const getDesignSystems = async (userId) => {
      try {
        setLoading(true);
        const { data } = await fetchSettingsDesignSystems(userId);
        setDesignSystems(data);
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setLoading(false);
      }
    };

    const adminId = getAdminId();
    getDesignSystems(adminId);

    const checkPermission = async (adminId, permission) => {
      try {
        setLoading(true);
        const { data } = await checkFeaturePermission(adminId, permission);
        setCanCreateDesignSystem(data);
      } catch (error) {
        toast.error(error.message);
        console.error("Failed to create design system", error);
      } finally {
        setLoading(false);
      }
    };
    checkPermission(adminId, "design_systems_per_user");

    const getFeatureValueAndUsage = async (adminId, permission) => {
      try {
        setLoading(true);
        const { data } = await fetchFeatureValueAndUsage(adminId, permission);
        setValueAndUsage(data);
      } catch (error) {
        toast.error(error.message);
        console.error("Failed to create design system", error);
      } finally {
        setLoading(false);
      }
    };

    getFeatureValueAndUsage(adminId, "design_systems_per_user");

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeCreateDSModal = () => {
    setCreateDSModalOpen(false);
  };
  const closeUpgradePlanAlertModal = () => {
    setUpgradePlanAlertModalOpen(false);
  };

  const handleCreateDStApply = async (request) => {
    const adminId = getAdminId();
    try {
      setLoading(true);
      const { data } = await addDesignSystem(adminId, request);
      setDesignSystems((prevState) => [...prevState, data]);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to create design system", error);
    } finally {
      setLoading(false);
    }
  };
  const handleUpgradePlantApply = () => {};

  return (
    <SettingsLayout>
      <div className='tab-content-wrapper'>
        <div className='tab-content-container dis-pr-48 dis-pl-48'>
          <div className={`plt-content-block dis-mb-32 w-100`}>
            <div className='plt-content-wrapper dis-mb-40 d-flex justify-content-between align-items-center'>
              <div className='plt-content-block-top'>
                <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
                  Design Systems
                </h6>
              </div>
              <div className='plt-btn-wrapper d-flex'>
                <button
                  className='plt-btn plt-btn-primary'
                  onClick={() => {
                    if (canCreateDesignSystem) {
                      setCreateDSModalOpen(true);
                    } else {
                      setUpgradePlanAlertModalOpen(true);
                    }
                  }}
                >
                  <span className='dss-icon dis-mr-4'>
                    {!canCreateDesignSystem ? (
                      <PremiumIcon />
                    ) : (
                      <PlusButtonWhiteicon />
                    )}
                  </span>
                  Add system
                </button>
                <div
                  className='plt-select-dropdown-wrapper plt-filter-dropdown'
                  ref={dropdownRef}
                >
                  <button
                    className='plt-btn plt-btn-secondary dis-ml-12'
                    onClick={() => setIsDropdownOpen((prev) => !prev)}
                  >
                    <span className='dss-icon dis-mr-4'>
                      {" "}
                      <FilterButtonicon />
                    </span>
                    Filters
                  </button>

                  <Dropdown
                    isOpen={isDropdownOpen}
                    onToggle={setIsDropdownOpen}
                    customClass=''
                    trigger={isDropdownOpen}
                  >
                    <ul>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Name
                      </li>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        URL
                      </li>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Access
                      </li>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Privacy
                      </li>
                    </ul>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="plan-steps-box d-flex align-items-center dis-pb-16">
              <div className="plan-steps-img dis-mr-8">
                <CircularLoader
                  percentage={(valueAndUsage?.value / valueAndUsage?.usage) * 100}
                />
              </div>
              <div className="plan-steps-content">
                <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                  {valueAndUsage?.usage || 0} of {valueAndUsage?.value} design system used
                </p>
              </div>
            </div>

            <DesignsystemsTable
              data={designSystems}
              setDesignSystems={setDesignSystems}
            />
          </div>
        </div>
      </div>
      <CreateDesignSystemModal
        isOpen={isCreateDSModalOpen}
        onRequestClose={closeCreateDSModal}
        onApply={handleCreateDStApply}
        title='Add new design system'
      />
      <UpgradePlanAlertModal
        isOpen={isUpgradePlanAlertModalOpen}
        onRequestClose={closeUpgradePlanAlertModal}
        onApply={handleUpgradePlantApply}
        title='Upgrade'
      />
    </SettingsLayout>
  );
};

export default Designsystems;
