import React, { useState } from "react";
import "../../assets/scss/pages/_DesignSystemComponents.scss";
import DesignSystemLayout from "../../components/DesignSystemPage/DesignSystemLayout";
import { useParams } from "react-router-dom";
import { getComponentsSideData } from "../../assets/data/sideTabData";
import PreviewTooltipComponent from "../../components/DesignSystemPage/TooltipComponent/PreviewTooltipComponent";
import { useGlobalContext } from "context/GlobalContext";
import UsageEditor from "components/DesignSystemPage/UsageEditor";
import UsageTooltipComponent from "components/DesignSystemPage/TooltipComponent/UsageTooltipComponent";

const Tooltip = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();

  const tabData = [
    "Short",
    "Truncate",
    "Wrap",
    // "Neutral colors",
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const bannerData = {
    heading: "Tooltip",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        // return <div>content1</div>;
        return (
          <PreviewTooltipComponent
            tabData={tabData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setSelectedTab={setSelectedTab}
          />
        );
      case 1:
        return (
          <div>
            <UsageTooltipComponent/>
          </div>
        );
      case 2:
        return <div>content3</div>;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title='Components'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default Tooltip;
