import React, { useState } from "react";
import CommonModal from "components/Common/CommonModal";
import { updateToken } from "api";

import "./ScaleTableSpacing.scss"; // Make sure the path matches where your SCSS file is located
import EditButton from "components/Buttons/EditButton";
import { addToLocalStorage } from "hooks/storageUtils";
import { updateValueByPath } from "helpers/token";
import { useGlobalContext } from "context/GlobalContext";
import Spacing from "./../../../../../pages/styles/Spacing";
import AuthAction from "components/auth/AuthAction";
import Tooltip from "components/Tooltip";
import Permission from "components/auth/Permission";

const TableRow = ({ rowData, onSelect }) => {
  const { designSystemMeta } = useGlobalContext();

  const handleClick = () => {
    onSelect(rowData);
  };

  return (
    <tr onClick={handleClick}>
      <td className='plt-body-sm'>{rowData.value}</td>
      <td>
        <p className='plt-badge plt-body-sm'>{rowData.className}</p>
      </td>
      <td>
        <div className='d-flex justify-content-between scale-preview '>
          <span
            className={`dss-spacing-size`}
            style={{
              backgroundColor: rowData.colorHex,
              display: "inline-block",
              width: rowData.value,
            }}
          ></span>
          <div className='plt-table-hover-icon dis-ml-8'>
            <AuthAction>
              <Permission designSystem={designSystemMeta}>
                <div>
                  <Tooltip
                    position='left'
                    arrow='lft-center'
                    text='Edit spacing'
                  >
                    <EditButton />
                  </Tooltip>
                </div>
              </Permission>
            </AuthAction>
          </div>
          {/* <AuthAction>
            <EditButton className='edit-button' />
          </AuthAction> */}
        </div>
      </td>
    </tr>
  );
};

const ScaleTableSpacing = ({ headers, data }) => {
  const [selectedSpaceData, setSelectedSpaceData] = useState({
    key: "",
    value: "",
  });
  const [isSpaceScaleModalOpen, setSpaceScaleModalOpen] = useState(false);
  const [newSpacingValue, setNewSpacingValue] = useState(""); // add a new state for the input value
  const { designSystem } = useGlobalContext();

  const handleSelect = (rowData) => {
    const valueWithoutPx = rowData.value.replace("px", ""); // Remove 'px' when selecting
    setSelectedSpaceData(rowData);
    setSpaceScaleModalOpen(true);
    setNewSpacingValue(valueWithoutPx);
  };

  const handleApply = async () => {
    if (newSpacingValue) {
      const valueWithPx = `${newSpacingValue}px`; // Add 'px' when applying
      let tokens = [{ key: selectedSpaceData.key, value: valueWithPx }]; // update the value with the new spacing value
      addToLocalStorage(tokens);
      updateValueByPath(designSystem, selectedSpaceData.key, valueWithPx);
      setSpaceScaleModalOpen(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setNewSpacingValue(value);
  };

  return (
    <div className='plt-table-wrapper'>
      <div className='plt-table-container plt-table-secondary-container'>
        <table className='plt-table plt-spacing-scale-token-table'>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className='plt-table-head'>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map((row, index) => (
              <TableRow key={index} rowData={row[1]} onSelect={handleSelect} />
            ))}
          </tbody>
        </table>
      </div>

      <CommonModal
        isOpen={isSpaceScaleModalOpen}
        onRequestClose={() => setSpaceScaleModalOpen(false)}
        title='Change Space Scale'
        onApply={handleApply}
        onCancelText='Cancel'
        onApplyText='Apply'
      >
        <div className='plt-modal-body-content'>
          {selectedSpaceData && (
            <>
              <div className='plt-token-details'>
                <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                  Spacing
                </h6>
                <div className='plt-token-value-item d-flex align-items-center'>
                  <p className='plt-badge  h-fit plt-token-value-name plt-body-compact-sm dis-mr-12'>
                    {selectedSpaceData.className}
                  </p>
                  <span className='dis-mr-4'> : </span>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={newSpacingValue}
                      onChange={handleChange}
                      className='input-field input-field-custom'
                    />
                    <span className='input-suffix'>px</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </CommonModal>
    </div>
  );
};

export default ScaleTableSpacing;
