import SuperAdminLayout from "components/layouts/SuperAdminLayout";
import React, { useEffect, useState } from "react";
import PlanTable from "./PlanTable";
import { fetchPlans } from "api";
import { useGlobalContext } from "context/GlobalContext";

const Plan = () => {
  const { setLoading } = useGlobalContext();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const getPlans = async () => {
      try {
        setLoading(true);
        const { data } = await fetchPlans();
        setPlans(data);
      } catch (error) {
        console.error("Failed to fetch Plans", error);
      } finally {
        setLoading(false);
      }
    };

    getPlans();
  }, []);
  return (
    <SuperAdminLayout>
      <div className='tab-content-wrapper'>
        <div className='tab-content-container dis-pr-48 dis-pl-48'>
          {/* <div className='plt-content-block dis-mb-0 w-100'>
          <div className='plt-content-wrapper dis-mb-28'>
            <div className='plt-content-block-top d-flex justify-content-between align-items-center'>
              <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
              Teams
              </h6>
            </div>
          </div>
        </div> */}

          <div className={`plt-content-block dis-mb-32 w-100`}>
            <div className='plt-content-wrapper dis-mb-40 d-flex justify-content-between align-items-center'>
              <div className='plt-content-block-top'>
                {/* <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
              Members
              </h6> */}
                <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
                  Plans
                </h6>
              </div>
              <div className='plt-btn-wrapper d-flex'>
                {/* <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Description
            </p> */}
              </div>
            </div>
            <PlanTable data={plans} setPlans={setPlans} />
          </div>

          {/* <div className='d-flex align-items-center justify-content-between dis-pt-32'>
            <div className='plt-modal-footer dis-p-0 ml-auto'>
              <button className='plt-btn plt-btn-secondary'>Cancel</button>
              <button className='plt-btn plt-btn-primary'>Update teams</button>
            </div>
          </div> */}
        </div>
      </div>
    </SuperAdminLayout>
  );
};

export default Plan;
