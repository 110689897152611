import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom"; // For portal usage
import { fetchUserData } from "api/auth"; // Import your actual fetch function
import { useGlobalContext } from "context/GlobalContext";
import { SyncLoader } from "react-spinners";
import RouteLoader from "../RouteLoader";
import { getSubdomainFromUrl } from "helpers/domain";
import { fetchDesignSystemBySubdmain } from "api";

const GuestRoute = ({ children }) => {
  const { currentDomain, setCurrentDomain } = useGlobalContext();
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Set initial state to null
  const [designSystemId, setDesignSystemId] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = process.env.REACT_APP_GUEST_TOKEN;

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const subdomain = getSubdomainFromUrl();

    if (token && !storedToken) {
      localStorage.setItem("token", token);
    }

    try {
      fetchDesignSystemBySubdmain(subdomain).then(({ data }) => {
        if (data) {
          setDesignSystemId(data.id);
          if (data.type === 2) {
            navigate(`/${data.id}/overview/getting-started`);
          } else {
            navigate(`/dashboard`);
          }
        } else {
          navigate("/dashboard");
        }
      });
    } catch (error) {
      console.error("Error in authentication:", error);
      navigate(`/dashboard`);
    }

    if (token && !storedToken) {
      localStorage.setItem("token", token);

      try {
        fetchUserData(token).then((response) => {
          if (response) {
            localStorage.setItem("user", JSON.stringify(response.data));
            setIsAuthenticated(true);
            setCurrentDomain(response.data.current_domain);
            // Clean URL after storing user and token
            // navigate(location.pathname, { replace: true });
          } else {
            setIsAuthenticated(false);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error("Error in authentication:", error);
        setIsAuthenticated(false);
        setLoading(false);
        navigate(`/${designSystemId}/overview/getting-started`);
      }
    } else {
      // Check local storage if URL params are missing

      if (storedToken) {
        setIsAuthenticated(true);
        setLoading(false);
        return; // Skip further processing if token exists in localStorage
      }
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, [token, location.pathname, navigate]);

  // Loader UI component for displaying full-screen spinner
  const Loader = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          fontSize: "1.5em",
          color: "white",
          zIndex: 10000,
        }}
      >
        <SyncLoader color='#fff' loading={true} />
      </div>
    </div>
  );

  // Render loading spinner if the authentication check is still in progress
  if (loading) {
    return createPortal(<Loader />, document.body); // Use portal to ensure it's rendered in the right DOM tree
  }

  // Render the protected content if authenticated
  return <RouteLoader>{children}</RouteLoader>;
};

export default GuestRoute;
