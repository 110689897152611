import React from "react";
import { NameInitialsAvatar } from "react-name-initials-avatar";

const UserImage = ({ user }) => {
  const apiUrl = process.env.REACT_APP_SHEET_URL;
  return user?.image ? (
    <img
      src={`${apiUrl}${user.image_url}`}
      className='user-image'
    />
  ) : (
    <NameInitialsAvatar
      bgColor='black'
      borderColor='black'
      textColor='white'
      name={user?.name?.toUpperCase()}
    />
  );
};

export default UserImage;
