import React from "react";
import emptyguidelines1 from "../../../assets/images/png/emptyguidelines1.png";
import emptyguidelines2 from "../../../assets/images/png/emptyguidelines2.png";


const ComponentPrimaryDatas = [
  {
    id: 1,
    title: "Do",
    titleClassname: "active",
    image: emptyguidelines1,
    description:
      "Incorporate a relevant call to action that clearly directs users towards the desired next step. Ensure it is visually prominent and easy to understand.",
  },
  {
    id: 2,
    title: "Don’t",
    titleClassname: "inactive",
    image: emptyguidelines2,
    description:
      "Avoid cluttering the page with too many call to action buttons, as this can overwhelm users and reduce the impact of the main action.",
  },
];

const GuidelineCardEmpty = () => {
  return (
    <div className='guideline-card d-flex justify-content-between'>
      {ComponentPrimaryDatas.map((data) => (
        <div key={data.id} className='guideline-wrapper'>
          <h6
            className={`guideline-badge plt-body-compact-md dis-mb-8 ${data.titleClassname}`}
          >
            {data.title}
          </h6>
          <div className='guideline-detail-wrapper d-flex align-items-center justify-content-center'>
            <div className='guideline-img'>
              <img src={data.image} alt='userimage' />
            </div>
          </div>
          <p className='guideline-desc plt-body-md dis-mt-12'>
            {data.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default GuidelineCardEmpty;
