import { useEffect, useState } from "react";
import Select from "react-select";
// import "components/Common/CustomSelect/CustomSelect.scss";

const Dropdown = ({
  options,
  selectedOption,
  onSelect,
  defaultValue,
  className,
  name,
  suffix = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selectedOption);

  // const dropdownRef = useRef(null);

  const [formattedOptions, setFormattedOptions] = useState([]);

  useEffect(() => {
    if (suffix === "px") {
      const newOptions = options.map((option) => ({
        value: `${option.value}`, // using 'key' as the value for the select option
        label: `${option.value}${suffix}`, // creating a user-friendly label
      }));
      setFormattedOptions(newOptions);
    } else if (suffix === "Major") {
      const newOptions = options.map((option) => ({
        value: `${option.value}`, // using 'key' as the value for the select option
        label: `${option.name}`, // creating a user-friendly label
      }));
      setFormattedOptions(newOptions);
    } else {
      const newOptions = options.map((option) => ({
        value: option.value, // using 'key' as the value for the select option
        label: `${option.value}`, // creating a user-friendly label
      }));
      setFormattedOptions(newOptions);
    }
  }, [options]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    setSelectedValue(option);
    onSelect(name, option); // Pass the selected option to the callback
    setIsOpen(false); // This line closes the dropdown
  };

  // Clicking outside the dropdown should close it
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }), // removes the "stick"
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      "& svg": { display: "none" },
    }),
    control: (base, state) => ({
      ...base,
      // background: "#fff",
      border: state.menuIsOpen
        ? "1px solid transparent"
        : "1px solid transparent",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.menuIsOpen
          ? "1px solid transparent"
          : "1px solid transparent",
      },
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "18px",
        right: "18px",
        width: "1.5px",
        height: "7px",
        borderRadius: "1px",
        background: state.menuIsOpen ? "#171717" : "#171717",
        transform: state.menuIsOpen ? "rotate(-45deg)" : "rotate(-135deg)",
        transition: "All .3s",
        // zIndex: "1",
      },
      "&:after": {
        right: "22px",
        transform: state.menuIsOpen ? "rotate(45deg)" : "rotate(135deg)",
      },
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: "#f6f6f6",
      boxShadow: "none",
      color: "#171717",
      fontSize: "14px",
      padding: "12px 24px",
      borderRadius: "4px",
      lineHeight: "120%",
      zIndex: "90",
      margin: "0",
      Cursor: "pointer",
      // 'min-width': "155px",
      "&:hover": {
        backgroundColor: "#dddddd",
        Cursor: "pointer",
      },
    }),
  };

  return (
    <div
      className={`custom-dropdown ${className}`}
      // ref={dropdownRef}
    >
      {/* <div
        className='dropdown-header plt-font-color-primary'
        onClick={toggleDropdown}
      >
        {selectedValue?.name}
        <span className='plt-custom-select-icon'>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12.6668 5.6665L8.00016 10.3332L3.3335 5.6665'
              stroke='#171717'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className='dropdown-list'>
          {options?.map((option, index) => (
            <div
              key={index}
              className={`dropdown-item plt-ui-text-sm-medium plt-font-color-primary ${selectedValue?.value === option?.value ? "selected" : ""
                }`}
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )} */}
      <Select
        classNamePrefix="react-select"
        defaultValue={{
          label:
            suffix === "Major"
              ? `${selectedValue.name}`
              : `${selectedValue.value}${suffix}`,
          value: selectedValue.value,
        }}
        styles={customStyles}
        // value={selectedValue?.value}
        onChange={handleSelect}
        menuPosition="fixed"
        options={formattedOptions}
        // isMulti={multi}
      />
    </div>
  );
};

export default Dropdown;
