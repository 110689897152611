import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import ToasterDetail from "../../../../assets/images/png/ToasterDetail.png";
import toasterPlacement from "../../../../assets/images/png/toasterPlacement.png";
import toasterSpacing from "../../../../assets/images/png/toasterSpacing.png";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
  { number: 1, title: 'Container', description: 'Holds and visually organizes controls and indicators for user interaction' },
  { number: 2, title: 'Supporting text', description: 'Provides instructions or feedback, enhancing clarity and guiding user actions.' },
  { number: 3, title: 'Action (optional)', description: 'Interactive elements for adjusting settings, offering customizable options.' },
  { number: 4, title: 'Close button (optional)', description: 'Allows users to dismiss or cancel the process if desired.' },
  
];


const UsageToasterComponent = () => {
  const { isLoading } = useGlobalContext();
  const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];
  // const [selectedTab, setSelectedTab] = useState(0);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            The toaster component features interactive controls for adjusting settings and a progress indicator for toasting status. These elements are designed for intuitive use and clear feedback, ensuring a seamless user experience.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={ToasterDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map(detail => (
              <DetailItem  
                key={detail.number} 
                number={detail.number} 
                title={detail.title} 
                description={detail.description} 
              />
            ))}
          </ul>
        
          <div className="dis-mt-40 dis-mb-40">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
              Placement
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              Ensure the toaster components are strategically placed for easy access and efficiency. This placement enhances usability and streamlines the user experience.
              </p>
            </div>
            
            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={toasterPlacement} alt="userimage" />
            </div>


            <div className="plt-content-wrapper dis-mb-24 dis-mt-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
              Spacing
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
              Maintain consistent spacing around the text field to ensure it is visually distinct and easily accessible. Adequate spacing improves readability and prevents a cluttered interface.
              </p>
            </div>

            <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
              <img src={toasterSpacing} alt="userimage" />
            </div>

          </div>
          
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageToasterComponent;
