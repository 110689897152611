import { useGlobalContext } from "context/GlobalContext";
import { getSubdomainFromUrl } from "helpers/domain";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDesignSystem } from "../api";
import HomeHeader from "../components/Home/HomeHeader/HomeHeader";
import HomeMain from "../components/Home/HomeMain/HomeMain";
const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading, designSystems, setDesignSystems, currentDomain } =
    useGlobalContext();
  const [isLoading, setIsLoading] = useState(true);
  const userIdState = location?.state?.userId;
  const userIdStateToken = location?.state?.token;

  const user = JSON.parse(localStorage.getItem("user"));
  const userToken = localStorage.getItem("token");
  if (user?.role === "guest") {
    navigate("/login");
  }

  // Function to handle the domain redirection logic
  const navigateToCustomDomain = () => {
    if (!currentDomain || !currentDomain.name) {
      console.error("currentDomain is not available in the global context");
      return;
    }

    const currentSubdomain = getSubdomainFromUrl();
    const customDomainName = currentDomain.name;
    const currentPath = window.location.pathname;

    // If the current subdomain is not the same as currentDomain.name, strip the unwanted subdomain
    if (
      currentSubdomain &&
      currentSubdomain !== customDomainName &&
      process.env.REACT_APP_ENV === "production"
    ) {
      // console.log(
      //   `Redirecting from subdomain: ${currentSubdomain} to ${customDomainName}`
      // );
      const newHref = `${window.location.protocol}//${customDomainName}.dessign.systems${currentPath}`;
      window.location.href = newHref;
      return;
    }
  };

  useEffect(() => {
    // Perform domain redirection check on component mount
    navigateToCustomDomain();

    // Fetch design systems after checking domain redirection
    const fetchDesignSystems = async () => {
      setLoading(true);
      try {
        const userId = userIdState || user?.id;
        const userTokenFinal = userIdStateToken || userToken;

        if (userId && userTokenFinal) {
          const data = await fetchDesignSystem(userId, userTokenFinal);
          setDesignSystems(data);
        } else {
          console.error("User ID or token not found");
        }
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setIsLoading(false);
        setLoading(false);
      }
    };

    fetchDesignSystems();
  }, [userIdState, userIdStateToken, user?.id, userToken]);

  return (
    <div>
      <HomeHeader />
      <HomeMain
        designSystems={designSystems}
        setDesignSystems={setDesignSystems}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Home;
