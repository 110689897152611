const getSubdomainHref = (domainName) => {
  let newHref = window.location.origin;
  const mainDomain = getMainDomain(window.location.origin);
  if (domainName) {
    newHref = `${window.location.protocol}//${domainName}.${mainDomain}`;
  }

  return newHref;
};

const getMainDomain = (url) => {
  const { hostname } = new URL(url);
  const parts = hostname.split(".");
  console.log(hostname, parts.length);
  if (parts.length > 2) {
    return parts.slice(-2).join(".");
  } else {
    return hostname;
  }
};
// Helper function to get the subdomain from the current hostname
const getSubdomainFromUrl = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  if (parts.length > 2) {
    return parts[0]; // Return the subdomain (e.g., "qw" or "jasir-team")
  }
  return null;
};



export { getSubdomainHref, getMainDomain, getSubdomainFromUrl };
