import React, { useState } from "react";
import "./Content.scss";
import TabNavigation from "../TabNavigation";
import ContentImg from "../../../assets/images/png/ContentImg.png";

const Content = () => {

  const tabData = [
    "Voice and tone",
    "Principles of our tone",
    
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className='d-flex justify-content-between w-full'>
      <div className='tab-content-container dis-pr-48 dis-pl-48'>
        <div className='plt-content-block dis-mb-24'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
            Voice and tone
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Effective content is the bridge between users and products. This guide outlines our preferred style for creating clear, engaging, and consistent written content.
          </p>
        </div>
     
        <div className='plt-content-block dis-mb-24'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
            Establishing our voice
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Our tone is built on principles that reflect our commitment to empathy, clarity, and inclusivity. These principles guide our communication, fostering trust and connection with our users.
          </p>
        </div>
        <div className='plt-content-block dis-mb-24'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary'>
            Be clear and keep it simple
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Our voice is conversational. It's like talking to a knowledgeable friend – approachable, yet professional. Our writing style, in turn, aims for efficiency and professionalism while upholding our branding. The clarity and simplicity in the language we use must signify our character. In order to execute this, use straightforward language that users can easily understand. Avoid unnecessary complexity and jargon. If a simpler word conveys the meaning, use it.
          </p>
        </div>
        <div className='plt-content-block dis-mb-24'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary'>
            Stay inclusive
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Maintaining inclusivity in the language used throughout the product is important in upholding the core values of the brand. Keep accessibility in mind, and provide additional context when needed.
          </p>
        </div>
        <div className='plt-content-block dis-mb-16'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary'>
            Empathy is key
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Maintaining inclusivity in the language used throughout the product is important in upholding the core values of the brand. Keep accessibility in mind, and provide additional context when needed.
          </p>
        </div>
        <div className="plt-img-wrapper d-flex align-items-center justify-content-center  dis-mb-48">
            <img src={ContentImg} alt="userimage" className="w-100" />
          </div>
        <div className='plt-content-block dis-mb-24'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
            Principles of our tone
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Maintaining inclusivity in the language used throughout the product is important in upholding the core values of the brand. Keep accessibility in mind, and provide additional context when needed.
          </p>
        </div>
        <div className='plt-content-block dis-mb-16'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary'>
            Consistency among channels
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Our tone remains consistent across various channels, from social media to support interactions. However, the nuances of each channel must be considered to adapt our tone to suit the platform while maintaining our brand essence.
          </p>
        </div>
        <div className='plt-content-block dis-mb-16'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary'>
            Conversational flow
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Cultivate a conversational tone. Engage users in a friendly and approachable manner.
          </p>
        </div>
        <div className='plt-content-block dis-mb-16'>
          <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary'>
            Keeping it light
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
          Humor can be a powerful tool, but use it judiciously. Ensure that humor aligns with our brand values and the context of the communication.
          </p>
        </div>
      </div>
      <div className='plt-token-contents '>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default Content;
