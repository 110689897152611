import SuperAdminLayout from "components/layouts/SuperAdminLayout";
import React from "react";

const SuperAdmin = () => {
  return (
    <SuperAdminLayout>
      <div className='tab-content-wrapper'>
        <div className='tab-content-container dis-pr-48 dis-pl-48'>
          Super admin settings
        </div>
      </div>
    </SuperAdminLayout>
  );
};

export default SuperAdmin;
