import React, { useEffect, useState } from "react";
import CommonModal from "components/Common/CommonModal";
import './ForgotPasswordModal.scss'

const ForgotPasswordModal = ({ isOpen, onRequestClose, onApply, title }) => {


  return (
    <div>
      <CommonModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={title}
        footerClassName={'plt'}
        // onApply={handleApply}
        onApply={onApply}
        onCancelText='Cancel'
        onApplyText='Continue'
      >
        <div className='plt-modal-body-content'>
          <div className="plt-verification-link-wrapper">
            <p className="plt-body-md plt-font-color-primary dis-mt-12 dis-pb-28">An email with a one-time password (OTP) for password reset has been sent to your registered address. Please check your inbox and spam folder.</p>
          </div>
        </div>
      </CommonModal>


    </div>
  );
};

export default ForgotPasswordModal;
