import UserMenu from "components/UserMenu";
import React from "react";
import UserImage from "assets/images/png/userAvatar.png";

const SettingsHeader = ({ user }) => {
  const apiUrl = process.env.REACT_APP_SHEET_URL;
  return (
    <div className='setting-profile-dropdown'>
      <div className='tab-profile-dropdown'>
        <UserMenu
          UserImage={user?.image ? `${apiUrl}${user?.image_url}` : UserImage}
          showDomain={false}
        />
      </div>
    </div>
  );
};

export default SettingsHeader;
