import { getUser } from "hooks/storageUtils";
import React from "react";

const AuthAction = ({ children }) => {
  const user = getUser();
  return (
    <>{user?.role !== "admin" && user?.role !== "member" ? null : children}</>
  );
};

export default AuthAction;
