import { sendContactUsMail } from "api";
import CommonModal from "components/Common/CommonModal";
import { getUser } from "hooks/storageUtils";
import React, { useState } from "react";

const ContactusModal = ({ isOpen, onRequestClose, onApply, title }) => {
  const user = getUser();
  const [message, setMessage] = useState();

  const handleInputChange = (value) => {
    setMessage(value);
  };

  const handleAppy = () => {
    onApply(message);
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      footerClassName='plt'
      onApply={handleAppy}
      onCancelText='Cancel'
      onApplyText='Continue'
      // bodyClassName='overflow-unset'
    >
      <div className='upgrade-contact dis-ml-24 dis-mr-24'>
        <h4 className='plt-heading-sm plt-font-color-primary dis-pb-12'>
          Add mail
        </h4>

        <div className='account-container'>
          <div className='plt-select-dropdown-wrapper dis-mb-24'>
            <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular font-weight-500 dis-mb-4'>
              Name
            </h6>
            <div className='plt-multi-dropdown-wrapper'>
              <input
                type='text'
                className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                name='name'
                value={user?.name}
              />
            </div>
          </div>
          <div className='plt-select-dropdown-wrapper dis-mb-24'>
            <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular font-weight-500 dis-mb-4'>
              Email
            </h6>
            <div className='plt-multi-dropdown-wrapper'>
              <input
                type='text'
                className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                name='email'
                value={user?.email}
              />
            </div>
          </div>
          <div className='modal-textarea-wrapper w-full dis-p-0 dis-mb-16'>
            <h6 className='modal-textarea-title plt-ui-text-sm-regular'>
              Message
            </h6>
            <textarea
              className='modal-textarea w-100'
              placeholder='Type...'
              value={message}
              onChange={(e) => handleInputChange(e.target.value)}
            ></textarea>
            <div className='modal-textarea-info d-flex align-items-center justify-content-between dis-mt-4'>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                Helper text
              </span>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                0/500
              </span>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default ContactusModal;
