import React from "react";
import { useNavigate } from "react-router-dom";
import errorImg from "../assets/images/png/errorImg.png";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className='d-flex w-full'>
     
      <div className="error-container d-flex flex-column align-items-center justify-content-center w-100 h-100vh">
        <div className='error-img'>
            <img src={errorImg} alt='error' className='error-image' />
        </div>
        <h2 className="error-msg plt-heading-3xl plt-font-color-primary dis-mt-20 dis-mb-16">The page you are looking for doesn’t exist</h2> 
        <p className="error-txt plt-body-md plt-font-color-secondary">This page may have been moved or you type in the wrong URL</p> 
          <div className='go-back-btn dis-mt-32'>
            <button className="cursor-pointer plt-btn plt-btn-primary"  onClick={goHome}>
            Go back to home
            </button>
          </div>
        </div>

    </div>



  );
};

export default PageNotFound;
