import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import TextareDetail from "../../../../assets/images/png/TextareDetail.png";
import TextareFluidDetail from "../../../../assets/images/png/TextareFluidDetail.png";
import TextareDefault from "../../../../assets/images/png/TextareDefault.png";
import TextareFocused from "../../../../assets/images/png/TextareFocused.png";
import TextareFilled from "../../../../assets/images/png/TextareFilled.png";
import TextareDisabled from "../../../../assets/images/png/TextareDisabled.png";
import TextareaReadonly from "../../../../assets/images/png/TextareaReadonly.png";
import TextareaPlacement from "../../../../assets/images/png/TextareaPlacement.png";
import TextareaSpacing from "../../../../assets/images/png/TextareaSpacing.png";
import GuidelineCardTextarea from "../../../Common/GuidelineCardTextarea";
import { TextareaComponentUsagePrimaryDatas } from "../../../../assets/data/data";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
  { number: 1, title: 'Label', description: 'Describes the required information for the text area.' },
  { number: 2, title: 'Placeholder text (optional)', description: 'Avoid unless providing example entries; use labels for clarity.' },
  { number: 3, title: 'Input area', description: 'The space where users enter their text.' },
  { number: 4, title: 'Helper text', description: 'Offers guidance on acceptable formats or character limits.' },
];


const UsageTextareaComponent = () => {
  const { isLoading } = useGlobalContext();
  const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];
  // const [selectedTab, setSelectedTab] = useState(0);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            The text area component features a label and an input field, available in two styles: Default, with a fixed height for consistency, and Fluid, which adjusts to fit the available space for greater flexibility in layouts.
            </p>
          </div>
          <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-24">Default</h4>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={TextareDetail} alt="userimage" />
          </div>
          <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mt-32 dis-mb-24">Fluid</h4>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={TextareFluidDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map(detail => (
              <DetailItem  
                key={detail.number} 
                number={detail.number} 
                title={detail.title} 
                description={detail.description} 
              />
            ))}
          </ul>
          
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[1]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Types of text area states
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Text areas are essential UI elements, enabling users to input and manage information effortlessly.
            </p>
          </div>

          {TextareaComponentUsagePrimaryDatas.map((data, index) => (
            <div key={index} className="dis-mb-40">
             
              <div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
                    {data.sub_title_1}
                  </h4>
                  <div className="plt-component-card">
                    <img src={TextareDefault} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
                    {data.sub_title_2}
                  </h4>
                  <div className="plt-component-card">
                    <img src={TextareFocused} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
                    {data.sub_title_3}
                  </h4>
                  <div className="plt-component-card">
                    <img src={TextareFilled} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
                    {data.sub_title_4}
                  </h4>
                  <div className="plt-component-card">
                    <img src={TextareDisabled} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
                    {data.sub_title_5}
                  </h4>
                  <div className="plt-component-card">
                    <img src={TextareaReadonly} alt="userimage" />
                  </div>
                </div>
              </div>

              <p
                className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}
              >
                {data.detail}
              </p>
              {data.detail_list}
            </div>
          ))}


            <div className="dis-mt-40 dis-mb-40">
              <div className="plt-content-wrapper dis-mb-24">
                <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                Placement
                </h6>
                <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Text area widths should match the expected content length, aligning with grid columns. While users can't adjust height, avoid overly wide fields that disrupt the layout or data clarity.
                </p>
              </div>
              
              <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                <img src={TextareaPlacement} alt="userimage" />
              </div>


              <div className="plt-content-wrapper dis-mb-24 dis-mt-24">
                <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                Spacing
                </h6>
                <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Ensure consistent spacing around the text area for visual clarity and easy accessibility, enhancing readability and preventing a cluttered interface.
                </p>
              </div>

              <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                <img src={TextareaSpacing} alt="userimage" />
              </div>

            </div>

            <div className="plt-content-wrapper dis-mb-24">
            <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
            Guidelines
            </h6>
            <ul className="plt-detail-list dis-mt-12">
              <li className="plt-body-md plt-font-color-primary">Position labels to the left and top of fields.</li>
              <li className="plt-body-md plt-font-color-primary">Keep consistent spacing between labels and input fields.</li>
              <li className="plt-body-md plt-font-color-primary">Align all text areas consistently to the left.</li>
              <li className="plt-body-md plt-font-color-primary">Ensure even spacing around text areas for visual balance.</li>
            </ul>
          </div>
         

          <GuidelineCardTextarea />
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageTextareaComponent;
