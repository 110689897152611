import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

// Send contact us mail for plan upgrade
async function sendContactUsMail(message) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/contact-us`,
      { message },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in contact us:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export { sendContactUsMail };
