import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

async function fetchUserPermissions(userId) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/user-permission/${userId}`, {
      headers: {
        Authorization: `Bearer ${token || stateToken}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function manageUserPermission(request) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/user-permission`, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function fetchRoles() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
async function checkFeaturePermission(adminId, permission) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/subscriptions/check-permission/${adminId}/${permission}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
async function fetchFeatureValueAndUsage(adminId, permission) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/feature-value-usage/${adminId}/${permission}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export {
  fetchUserPermissions,
  manageUserPermission,
  fetchRoles,
  checkFeaturePermission,
  fetchFeatureValueAndUsage,
};
