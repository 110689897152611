import React from "react";
import { Navigate } from "react-router-dom";
import { isUserSuperAdmin } from "helpers/auth";

const SuperAdminRoute = ({ children }) => {
  const isSuperAdmin = isUserSuperAdmin();

  if (!isSuperAdmin) {
    return <Navigate to='/login' />;
  }

  return <>{children}</>;
};

export default SuperAdminRoute;
