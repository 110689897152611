import { tokenDropdown, uiTextTokens } from "api";
import { useGlobalContext } from "context/GlobalContext";
import React, { useEffect, useState } from "react";
import {
  ComponentBtnDatas,
  ComponentDangerDatas,
  ComponentFluidDatas,
  ComponentGostDatas,
  ComponentLinkDatas,
  ComponentOutlineDatas,
  ComponentPrimaryDatas,
  ComponentSecondaryDatas,
} from "../../../../assets/data/data";
import ComponentCard from "../../../../components/Common/ComponentCard";
import TabNavigation from "../../TabNavigation";
import "./PreviewButtonComponent.scss";

import Dropdown from "components/Dropdown";
import { EditIcon } from "components/Icons";
import TokenDropdown from "components/TokenDropdown";
import { getCssVariableValue } from "helpers/style";
import {
  getValueByPath,
  replaceCharfromKey,
  updateCSSVariablesAllObj,
} from "helpers/token";
import { addToLocalStorage } from "hooks/storageUtils";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import useTokensData from "../../../../assets/data/buttonTokenData";
import useScrollManagement from "../../../../hooks/useScrollManagement";
import colorSectionData from "../../../../pages/styles/colorSectionData";
import CommonModal from "../../../Common/CommonModal";
import PrimaryButton from "../../../Common/PrimaryButton";
import ColorPickerComponent from "../../ColorPickerComponent";

const getSpacingValues = (selectedTokenData, buttonState, buttonSize) => {
  const spacingData =
    selectedTokenData[buttonState?.value.toLowerCase()]?.spacing;
  if (!spacingData) {
    return { horizontal: "", vertical: "" };
  }
  const spacingValue = spacingData[buttonSize]?.value || "";

  // Destructure values with first as vertical and second as horizontal
  const [vertical, horizontal] = spacingValue
    .split(" ")
    .map((val) => val.replace(/[{}]/g, ""));

  const getCssVariableValue = (variable) => {
    const element = document.documentElement; // or any specific element
    return getComputedStyle(element).getPropertyValue(variable)?.trim();
  };

  const horizontalVar = `--dss-${horizontal?.replace(".", "-")}`;
  const verticalVar = `--dss-${vertical?.replace(".", "-")}`;

  return {
    horizontal: {
      label: horizontal,
      value: getCssVariableValue(horizontalVar),
    },
    vertical: {
      label: vertical,
      value: getCssVariableValue(verticalVar),
    },
  };
};

const PreviewButtonComponent = ({ setSelectedTab }) => {
  const colorSections = colorSectionData();

  const { sheetIsLoading, isLoading, setButtonSelectedColor, designSystem } =
    useGlobalContext();

  const [selectedTokenData, setSelectedTokenData] = useState({});
  const tabData = [
    "Primary",
    "Secondary",
    "Outline",
    "Danger",
    "Ghost",
    "Link",
  ];

  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
      >
        usage
      </span>
    </p>
  );

  const [selectedtextdecoration, setSelectedtextdecoration] =
    useState("Underline");
  const textdecoration = [
    { value: "Underline", name: "Underline" },
    { value: "Overline", name: "Overline" },
    { value: "line-through", name: "line-through" },
    { value: "None", name: "None" },
  ];
  const handleTextdecorationChange = (name, value) =>
    setSelectedtextdecoration(value);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  const ColorSectionComponent = ({
    title,
    description,
    colorData,
    type,
    setTokenState,
    buttonState,
    tokenState,
    initialColor,
  }) => {
    return (
      <div className="plt-content-wrapper dis-mb-32">
        {title && type?.length && (
          <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-12">
            {title}
          </h6>
        )}
        {description && !type?.length && (
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-24">
            {description}
          </p>
        )}

        {colorData.map((color, index) => (
          <>
            {color?.subTitle && (
              <p className="plt-subtitle plt-heading-xs plt-font-color-primary dis-mb-8">
                {color?.subTitle}
              </p>
            )}
            <ColorPickerComponent
              key={index}
              initialColor={initialColor}
              buttonState={buttonState}
              setTokenState={setTokenState}
              type={type}
              tokenState={tokenState}
              baseColor={color.baseColor}
              id={color.id}
              shades={color.shades}
              tokenType={color.tokenType}
            />
          </>
        ))}
      </div>
    );
  };

  const [spaceHValue, setSpaceHValue] = useState({ label: "", value: "" });
  const [spaceVValue, setSpaceVValue] = useState({ label: "", value: "" });
  const tokensData = useTokensData();

  const { id } = useParams();
  const [isModalVisible, setModalVisibility] = useState(false);
  const buttonStates = [
    { value: "Default", name: "Default" },
    { value: "Hovered", name: "Hovered" },
    { value: "Pressed", name: "Pressed" },
    { value: "Disabled", name: "Disabled" },
  ];
  const [buttonState, setButtonState] = useState(() => buttonStates[0]);
  const [buttonSize, setButtonSize] = useState();
  const [isColorTokenModalOpen, setColorTokenModalOpen] = useState(false);
  const [isPaddingTokenModalOpen, setPaddingTokenModalOpen] = useState(false);
  const [isShadowTokenModalOpen, setShadowTokenModalOpen] = useState(false);
  const [isTextTokenModalOpen, setTextTokenModalOpen] = useState(false);
  const [isTypoTokenModalOpen, setTypoTokenModalOpen] = useState(false);
  const [tokenType, setTokenType] = useState("");
  const [isRadiusTokenModalOpen, setRadiusTokenModalOpen] = useState(false);
  const [tokenState, setTokenState] = useState({ key: "", value: "" });
  const [selectedClassName, setSelectedClassName] = useState("");
  const [selectedTypography, setSelectedTypography] = useState([]);
  //shadow change function and state
  const [selectedShadow, setSelectedShadow] = useState(
    selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[buttonSize]
  );
  const [boxShadowStyle, setBoxShadowStyle] = useState([]);

  console.log(buttonState, "buttonState");

  useEffect(() => {
    const initialShadowPath =
      selectedTokenData &&
      replaceCharfromKey(
        selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[
          buttonSize
        ]?.value
      );

    const tokenValue = initialShadowPath
      ? getValueByPath(designSystem.core, initialShadowPath)
      : "";
    setSelectedShadow(tokenValue);
  }, [selectedTokenData]);

  useEffect(() => {
    const constructBoxShadow = (shadow) => {
      if (!shadow) return "";

      const { x, y, blur, spread, color } = shadow;
      return `${x} ${y} ${blur} ${spread} ${getCssVariableValue(color)}`;
    };
    setBoxShadowStyle(constructBoxShadow(selectedShadow?.value));
  }, [selectedTokenData, selectedShadow]);

  const radiusValues = designSystem?.core?.borderRadius
    ? Object.keys(designSystem?.core?.borderRadius).map((key, index) => ({
        id: key,
        key: `{borderRadius.${key}}`,
        label: `dss-border-radius-${key}`,
        value: designSystem?.core?.borderRadius[key]?.value,
      }))
    : [];

  // Extract the radius key from selectedTokenData and remove curly braces

  const [selectedRadius, setSelectedRadius] = useState({
    key: designSystem?.light?.borderRadius?.button?.[buttonSize]?.value,
  });

  useEffect(() => {
    if (selectedTokenData && buttonState && buttonSize && designSystem) {
      const { horizontal, vertical } = getSpacingValues(
        selectedTokenData,
        buttonState,
        buttonSize
      );
      setSpaceHValue(horizontal);
      setSpaceVValue(vertical);
    }
    setSelectedRadius({
      key: designSystem?.light?.borderRadius?.button?.[buttonSize]?.value,
    });
  }, [selectedTokenData, buttonState, buttonSize, designSystem]);

  const spaces = designSystem?.core?.space
    ? Object.keys(designSystem?.core?.space).map((key) => ({
        label: `space.${key}`,
        value: designSystem?.core?.space[key]?.value,
      }))
    : [];

  const handleHorizontalChange = (selectedOption) => {
    setSpaceHValue(selectedOption);
  };

  const handleVerticalChange = (selectedOption) => {
    setSpaceVValue(selectedOption);
  };

  const handleRadiusChange = (item) => {
    setSelectedRadius(item);
  };

  const handleStateChange = (name, value) => {
    setButtonState(value);
  };

  const onApplyChanges = async () => {
    addToLocalStorage([tokenState]);
    setColorTokenModalOpen(false);
  };

  const showModal = (className, tokenData) => {
    setModalVisibility(true);
    setSelectedClassName(className);
    setSelectedTokenData(tokenData);
  };

  const [selectedToken, setSelectedToken] = useState(null);

  useEffect(() => {
    const initialToken =
      selectedTokenData &&
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ];
    setSelectedToken(initialToken);
  }, [selectedTokenData]);

  const { setLoading, pusherIsLoading } = useGlobalContext();
  const [data, setData] = useState({});

  const [uiData, setUiData] = useState({});
  const [elevationData, setElevationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const types =
        "fontWeights,fontSizes,lineHeights,letterSpacing,fontFamilies,textDecoration";
      try {
        const data = await tokenDropdown(id, types);
        setData(data);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      }
    };

    const fetchUItext = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const userId = user?.id;
        setLoading(true);
        if (userId) {
          const { data } = await uiTextTokens(id);
          setUiData(data);
        } else {
          console.error("User ID not found in localStorage");
        }
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setLoading(false);
      }
    };
    if (!pusherIsLoading) {
      fetchData();
      fetchUItext();
    }
  }, [pusherIsLoading]);
  const filterUiTexts = (data, filter) =>
    data &&
    Object.values(data)?.filter((ui) => {
      return ui.key.includes(filter);
    });

  const uiTextData = filterUiTexts(uiData, "core.uiText");
  const elevationCoreData =
    designSystem?.core?.shadow[
      buttonState?.value === "Default"
        ? "fill"
        : buttonState?.value.toLowerCase()
    ];

  useEffect(() => {
    setElevationData(elevationCoreData);
  }, [buttonState, designSystem]);

  const handleTokenChange = (token) => {
    const varNamePrefix =
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ]?.varName;

    const cssVariables = {
      [`${varNamePrefix}-font-family`]: token.fontFamily.value,
      [`${varNamePrefix}-font-weight`]: token.fontWeight.value,
      [`${varNamePrefix}-font-size`]: token.fontSize.value,
      [`${varNamePrefix}-line-height`]: token.lineHeight.value,
    };
    updateCSSVariablesAllObj(cssVariables);
    setSelectedToken({ value: token.data, data: token });
    setSelectedTypography(token);
  };

  const updateCSSVariables = (varName, value) => {
    document.documentElement.style.setProperty(varName, value);
  };

  const handleApplyPaddingChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
          buttonSize
        ]?.key,
        value: `{${spaceVValue.label}} {${spaceHValue.label}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setPaddingTokenModalOpen(false);
  };

  const handleApplyShadowChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[
          buttonSize
        ]?.key,
        value: `{${selectedShadow.key}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setShadowTokenModalOpen(false);
  };

  const handleApplyTextChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
          buttonSize
        ]?.key,
        value: `{${spaceVValue.label}} {${spaceHValue.label}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setTextTokenModalOpen(false);
  };

  const handleApplyRadiusChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[
          buttonSize
        ].key,
        value: `${selectedRadius.key}`,
      },
    ];
    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[buttonSize]
        .varName,
      selectedRadius.value
    );
    addToLocalStorage(tokenData);

    setRadiusTokenModalOpen(false);
  };

  const handleApplyTypographyChange = () => {
    const key =
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ]?.key;

    const tokenData = [
      {
        key,
        value: selectedTypography.data,
      },
    ];

    addToLocalStorage(tokenData);
    setTypoTokenModalOpen(false);
  };

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        <div className="plt-content-wrapper dis-mb-24">
          <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary"
            >
              Primary
            </h6>
          </div>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentPrimaryDatas.map((data) => (
          <div key={data.id} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.className}
              edit={true}
              showCode={true}
              editClick={() => {
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }

                buttonSize && showModal(data.className, tokensData[0]?.primary);
              }}
            >
              {isLoading || sheetIsLoading ? (
                <Skeleton height={36} width={150} />
              ) : (
                <PrimaryButton
                  text={"Button text"}
                  className={`${data.className} ${
                    data.title.toLowerCase() === "disabled" ? "disabled" : ""
                  }`}
                />
              )}
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
            <h6 className="plt-contents-head plt-heading-md plt-font-color-primary">
              Fluid width
            </h6>
          </div>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentFluidDatas.map((data) => (
          <div key={data.id} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              edit={true}
              code={data.className}
              showCode={true}
              editClick={() => {
                showModal(data.className, tokensData[0]?.primary);
                setButtonSize("large");

                if (data?.title === "disabled") {
                  setButtonState("Disabled");
                }
              }}
            >
              <PrimaryButton
                text={"Button text"}
                className={`${data.className} ${
                  data.title.toLowerCase() === "disabled" ? "disabled" : ""
                }`}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6
            ref={sectionRefs.current[1]}
            className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
          >
            Secondary
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentSecondaryDatas.map((data) => (
          <div key={data.id} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              edit={true}
              code={data.className}
              showCode={true}
              editClick={() => {
                showModal(data.className, tokensData[2]?.default);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                text={"Button text"}
                className={`${data.className} ${
                  data.title.toLowerCase() === "disabled" ? "disabled" : ""
                }`}
              />
            </ComponentCard>{" "}
            <AllUsage />
          </div>
        ))}
        <div
          ref={sectionRefs.current[2]}
          className="plt-content-wrapper dis-mb-24"
        >
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Outline
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentOutlineDatas.map((data) => (
          <div key={data.id} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.className}
              edit={true}
              showCode={true}
              editClick={() => {
                showModal(data.className, tokensData[4]?.stroke);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton text={"Button text"} className={data.className} />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6
            ref={sectionRefs.current[3]}
            className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
          >
            Danger
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentDangerDatas.map((data) => (
          <div key={data.id} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              edit={true}
              showCode={true}
              code={data.className}
              editClick={() => {
                showModal(data.className, tokensData[1]?.danger);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton text={"Button text"} className={data.className} />
            </ComponentCard>{" "}
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6
            ref={sectionRefs.current[4]}
            className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
          >
            Ghost
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentGostDatas.map((data) => (
          <div key={data.id} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              edit={true}
              showCode={true}
              code={data.className}
              editClick={() => {
                showModal(data.className, tokensData[3]?.ghost);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                text={"Button text"}
                className={data.className}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6
            ref={sectionRefs.current[5]}
            className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
          >
            Link
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentLinkDatas.map((data) => (
          <div key={data.id} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.className}
              edit={true}
              showCode={true}
              editClick={() => {
                showModal(data.className, tokensData[5]?.link);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                text={"Button text"}
                className={data.className}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Button with icon
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentBtnDatas.map((data) => (
          <div key={data.id} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              code={data.className}
              showCode={true}
              edit={false}
              editClick={() => {
                showModal(data.className);
                if (data?.title === "disabled") {
                  setButtonSize("large");
                  setButtonState("Disabled");
                } else {
                  setButtonSize(data?.title?.toLowerCase());
                }
              }}
            >
              <PrimaryButton
                onClick={() => {}}
                prefixIcon={data.prefixIcon}
                suffixIcon={data.suffixIcon}
                text={"Button text"}
                className={data.className}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
      </div>
      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => {
          setModalVisibility(false);
          setButtonState(buttonStates[0]);
        }}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
                {/* <CustomSelect
                  options={buttonStates}
                  selectedOption={buttonState}
                  onSelect={handleStateChange}
                /> */}
                <Dropdown
                  name="buttonState"
                  options={buttonStates}
                  selectedOption={buttonState}
                  onSelect={handleStateChange}
                />
              </div>
            </div>
          </div>
          <div className="plt-preview dis-mb-32">
            <PrimaryButton
              // onClick={() => {}}
              style={{
                fontFamily: selectedToken?.data?.fontFamily?.value,
                fontSize: selectedToken?.data?.fontSize?.value,
                lineHeight: selectedToken?.data?.lineHeight?.value,
                fontWeight: selectedToken?.data?.fontWeight?.value,
              }}
              text={"Button text"}
              className={`${buttonState?.value.toLocaleLowerCase()} ${selectedClassName} d-flex m-auto `}
              disabled={buttonState?.value.toLowerCase() === "disabled"}
            />
          </div>
          {selectedTokenData &&
            selectedTokenData[buttonState?.value.toLocaleLowerCase()] && (
              <>
                {selectedTokenData[buttonState?.value.toLowerCase()].bg
                  ?.tokenName && (
                  <div className="plt-token-details dis-mb-32">
                    <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                      Background color
                    </h6>
                    <div className="plt-token-value-item d-flex">
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedTokenData[buttonState?.value.toLowerCase()].bg
                          .tokenName || "N/A"}
                      </p>
                      <span
                        className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                        onClick={() => {
                          setTokenType("bg");
                          setColorTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]?.border
                  ?.tokenName && (
                  <div className="plt-token-details dis-mb-32">
                    <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                      Border color
                    </h6>
                    <div className="plt-token-value-item d-flex">
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.border.tokenName || "N/A"}
                      </p>
                      <span
                        className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                        onClick={() => {
                          setTokenType("border");
                          setColorTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]
                  ?.typography?.[buttonSize]?.tokenName && (
                  <div className="plt-token-details dis-mb-32">
                    <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                      Typography
                    </h6>
                    <div className="plt-token-value-item d-flex">
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.typography?.[buttonSize]?.tokenName || "N/A"}
                      </p>
                      <span
                        className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                        onClick={() => {
                          setTokenType("text");
                          setTypoTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[
                  buttonSize
                ]?.className && (
                  <div className="plt-token-details dis-mb-32">
                    <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                      Radius
                    </h6>
                    <div className="plt-token-value-item d-flex">
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.radius?.[buttonSize]?.className || "N/A"}
                      </p>
                      <span
                        className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                        onClick={() => setRadiusTokenModalOpen(true)}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]?.text
                  ?.tokenName && (
                  <div className="plt-token-details dis-mb-32">
                    <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                      Text color
                    </h6>
                    <div className="plt-token-value-item d-flex">
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.text.tokenName || "N/A"}
                      </p>
                      <span
                        className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                        onClick={() => {
                          setTokenType("text");
                          setColorTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                {selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
                  buttonSize
                ]?.tokenName && (
                  <div className="plt-token-details dis-mb-24">
                    <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                      Padding
                    </h6>
                    <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mb-4">
                      {selectedTokenData[buttonState?.value.toLowerCase()]
                        ?.spacing?.[buttonSize]?.tokenName || "N/A"}
                    </p>
                    <div className="plt-token-value-item d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <span className="plt-body-compact-sm plt-font-color-primary dis-mr-4">
                          H:
                        </span>
                        <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                          {spaceHValue?.label || "N/A"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="plt-body-compact-sm plt-font-color-primary dis-mr-4">
                          V:
                        </span>
                        <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                          {spaceVValue?.label || "N/A"}
                        </p>
                      </div>
                      <span
                        className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                        onClick={() => {
                          setTokenType("text");
                          setPaddingTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
                <div className="plt-token-details dis-mb-24">
                  <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                    Box shadow
                  </h6>
                  <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mb-4">
                    {selectedTokenData[buttonState?.value.toLowerCase()]
                      ?.elevation?.[buttonSize]?.tokenName || "N/A"}
                  </p>
                  <div className="plt-token-value-item d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <span className="plt-body-compact-sm plt-font-color-primary dis-mr-4">
                        X:
                      </span>
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedShadow?.value?.x}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="plt-body-compact-sm plt-font-color-primary dis-mr-4">
                        Y:
                      </span>
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedShadow?.value?.y}
                      </p>
                    </div>
                  </div>
                  <div className="plt-token-value-item d-flex align-items-center dis-mt-12">
                    <div className="d-flex align-items-center">
                      <span className="plt-body-compact-sm plt-font-color-primary dis-mr-4">
                        Blur:
                      </span>
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedShadow?.value?.blur}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="plt-body-compact-sm plt-font-color-primary dis-mr-4">
                        Spread:
                      </span>
                      <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                        {selectedShadow?.value?.spread}
                      </p>
                    </div>
                    <span
                      className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                      onClick={() => {
                        setTokenType("text");
                        setShadowTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
                <div className="plt-token-details dis-mb-24">
                  <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                    Text decoration
                  </h6>
                  <div className="plt-token-value-item d-flex">
                    <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                      textdecoration-underline
                    </p>
                    <span
                      className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                      onClick={() => {
                        setTokenType("text");
                        setTextTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              </>
            )}
        </div>
      </CommonModal>

      <CommonModal
        isOpen={isColorTokenModalOpen}
        onRequestClose={() => {
          setButtonSelectedColor(null), setColorTokenModalOpen(false);
        }}
        title="Change token"
        onApply={onApplyChanges}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="change-token-modal"
      >
        <>
          <div className="plt-preview dis-mb-24">
            <PrimaryButton
              // onClick={() => {}}
              text={"Button text"}
              className={` ${buttonState?.value.toLowerCase()} ${selectedClassName}  d-flex m-auto`}
              disabled={buttonState === "disabled"}
            />
          </div>
          <div className="plt-modal-body-content dis-mt-32 dis-pl-24 dis-pr-24">
            {colorSections?.map((section, index) => (
              <div key={section.title}>
                <ColorSectionComponent
                  {...section}
                  onApplyChanges={onApplyChanges}
                  type={tokenType}
                  buttonState={buttonState}
                  tokenState={selectedTokenData}
                  setTokenState={setTokenState}
                  initialColor={
                    tokenType === "bg"
                      ? selectedTokenData[buttonState?.value.toLowerCase()]?.bg
                          ?.value
                      : tokenType === "text"
                      ? selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.text?.value
                      : tokenType === "border"
                      ? selectedTokenData[buttonState?.value.toLowerCase()]
                          ?.border?.value
                      : undefined
                  }
                />
              </div>
            ))}{" "}
          </div>
        </>
      </CommonModal>

      <CommonModal
        isOpen={isPaddingTokenModalOpen}
        onRequestClose={() => setPaddingTokenModalOpen(false)}
        title="Change token"
        onApply={() => handleApplyPaddingChange()}
        onCancelText="Cancel"
        onApplyText="Apply"
        // variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div
            className="plt-preview dis-mb-32"
            style={{ fontFamily: buttonState }}
          >
            <PrimaryButton
              // onClick={() => {}}
              text={"Button text"}
              className={` ${buttonState} ${selectedClassName} d-flex m-auto`}
              disabled={buttonState === "disabled"}
              style={{
                padding: `${spaceVValue.value}  ${spaceHValue.value}`,
              }}
            />
          </div>

          <div className="plt-padding-wrapper d-flex  dis-mb-32">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center">
              <h6 className="plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular">
                Horizontal
              </h6>
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-padding">
                <TokenDropdown
                  name="spaces"
                  options={spaces.map((space) => ({
                    value: space.value,
                    label: `${space.value}`,
                  }))}
                  selectedOption={{
                    value: spaceHValue.value,
                    label: spaceHValue.value,
                  }}
                  onSelect={(name, option) =>
                    handleHorizontalChange(
                      spaces.find((space) => space?.value === option?.value)
                    )
                  }
                />

                {/* <CustomSelect
                  options={spaces.map((space) => space.value)}
                  selectedOption={
                    spaceHValue.label ? spaceHValue.value : spaceHValue
                  }
                  onSelect={(label) =>
                    handleHorizontalChange(
                      spaces.find((option) => option.value === label)
                    )
                  }
                /> */}
              </div>
            </div>
            <div className="plt-select-dropdown-wrapper d-flex align-items-center">
              <h6 className="plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular">
                Vertical
              </h6>
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-padding">
                <TokenDropdown
                  name="spaces"
                  options={spaces.map((space) => ({
                    value: space.value,
                    label: `${space.value}`,
                  }))}
                  selectedOption={{
                    value: spaceVValue.value,
                    label: spaceVValue.value,
                  }}
                  onSelect={(event, option) =>
                    handleVerticalChange(
                      spaces.find((space) => space?.value === option?.value)
                    )
                  }
                />

                {/* <CustomSelect
                  options={spaces.map((space) => space.value)}
                  selectedOption={
                    spaceVValue.label ? spaceVValue.value : spaceVValue
                  }
                  onSelect={(label) =>
                    handleVerticalChange(
                      spaces.find((option) => option.value === label)
                    )
                  }
                /> */}
              </div>
            </div>
          </div>
        </div>
      </CommonModal>
      {isShadowTokenModalOpen && (
        <CommonModal
          isOpen={isShadowTokenModalOpen}
          onRequestClose={() => setShadowTokenModalOpen(false)}
          title="Change token"
          onApply={() => handleApplyShadowChange()}
          onCancelText="Cancel"
          onApplyText="Apply"
          // variant="modal-tertiary"
        >
          <div className="plt-modal-body-content">
            <div
              className="plt-preview dis-mb-32"
              style={{ fontFamily: buttonState }}
            >
              <PrimaryButton
                // onClick={() => {}}
                text={"Button text"}
                className={`pressed  ${buttonState} ${selectedClassName} d-flex m-auto`}
                disabled={buttonState === "disabled"}
                style={{
                  padding: `${spaceVValue.value}  ${spaceHValue.value}`,
                  boxShadow: boxShadowStyle,
                }}
              />
            </div>

            <div className="token-list dss-custom-radio-group flex-column">
              {Object.entries(elevationData)?.map(([key, item]) => {
                return (
                  <div
                    key={key}
                    onClick={() => setSelectedShadow(item)}
                    className="token-item"
                  >
                    <label
                      className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default  ${
                        selectedShadow?.key === item.key ? "checked" : ""
                      }`}
                    >
                      <input
                        className="dss-radio dss-radio-input dss-radio-default"
                        name="radio-group"
                        type="radio"
                        id={item.id}
                        value={item.value}
                        checked={selectedShadow?.key === item.key}
                      />
                      <div className="dss-radio-label" htmlFor={item.key}>
                        <p className="plt-badge plt-body-sm">
                          {item.tokenName}
                        </p>
                      </div>
                    </label>
                    <div className="token-info dis-ml-40">
                      <p className="d-flex plt-body-compact-sm font-color-black dis-mt-12">
                        <span className="plt-body-compact-sm font-color-black dis-mr-16">
                          X: {item.value.x}
                        </span>
                        <span className="plt-body-compact-sm font-color-black">
                          Y: {item.value.y}
                        </span>
                      </p>
                      <p className="d-flex plt-body-compact-sm font-color-black dis-mt-8">
                        <span className="plt-body-compact-sm font-color-black dis-mr-16">
                          Spread: {item.value.spread}
                        </span>
                        <span className="plt-body-compact-sm font-color-black">
                          Blur: {item.value.blur}
                        </span>
                      </p>
                      <p className="d-flex plt-body-compact-sm font-color-black dis-mt-12">
                        Color: {getCssVariableValue(item.value.color)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </CommonModal>
      )}

      <CommonModal
        isOpen={isTextTokenModalOpen}
        onRequestClose={() => setTextTokenModalOpen(false)}
        title="Change token"
        onApply={() => handleApplyTextChange()}
        onCancelText="Cancel"
        onApplyText="Apply"
        // variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div
            className="plt-preview dis-mb-32"
            style={{ fontFamily: buttonState }}
          >
            <PrimaryButton
              // onClick={() => {}}
              text={"Button text"}
              className={` ${buttonState} ${selectedClassName} d-flex m-auto`}
              disabled={buttonState === "disabled"}
              style={{
                padding: `${spaceVValue.value}  ${spaceHValue.value}`,
              }}
            />
          </div>

          <div className="plt-padding-wrapper d-flex  dis-mb-32">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center w-100">
              <h6 className="plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular">
                Text decoration
              </h6>
              <div className="plt-dropdown-wrapper">
                {/* <CustomSelect
                  options={textdecoration}
                  selectedOption={selectedtextdecoration}
                  onSelect={handleTextdecorationChange}
                /> */}
                <TokenDropdown
                  name="textdecoration"
                  options={textdecoration.map((textdecoration) => ({
                    value: textdecoration.value,
                    label: `${textdecoration.value}`,
                  }))}
                  selectedOption={{
                    value: selectedtextdecoration,
                    label: selectedtextdecoration,
                  }}
                  // onSelect={(name, option) =>
                  //   handleTextdecorationChange(
                  //     textdecoration.find((textdecoration) => textdecoration?.value === option?.value)
                  //   )
                  // }
                  onSelect={handleTextdecorationChange}
                />
              </div>
            </div>
          </div>
        </div>
      </CommonModal>

      <CommonModal
        isOpen={isRadiusTokenModalOpen}
        onRequestClose={() => {
          setSelectedRadius({
            key: designSystem?.light?.borderRadius?.button?.[buttonSize]?.value,
          });
          setRadiusTokenModalOpen(false);
        }}
        title="Change token"
        onApply={() => handleApplyRadiusChange()}
        onCancelText="Cancel"
        onApplyText="Apply"
      >
        <div className="plt-modal-body-content">
          <div className="modal-token-wrapper">
            <div className="plt-preview dis-mb-32">
              <PrimaryButton
                // onClick={() => {}}
                text={"Button text"}
                className={` ${buttonState} ${selectedClassName}  d-flex m-auto`}
                style={{ borderRadius: selectedRadius?.value }}
              />
            </div>

            <div className="token-list dss-custom-radio-group flex-column">
              {radiusValues &&
                radiusValues.map((item) => (
                  <div key={item.id} className="token-item">
                    <label
                      className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default ${
                        selectedRadius?.key === item.key ? "checked" : ""
                      }`}
                    >
                      <input
                        className="dss-radio dss-radio-input"
                        name="radio-group"
                        type="radio"
                        id={item.id}
                        value={item.value}
                        onChange={() => handleRadiusChange(item)}
                        checked={selectedRadius?.key === item.key}
                      />
                      <div
                        className="dss-radio-label dss-body-compact-md"
                        htmlFor={item.id}
                      >
                        <p className="plt-badge plt-body-sm">{item.label}</p>
                      </div>
                    </label>
                    <div className="token-info dis-ml-40">
                      <p className="plt-body-compact-sm font-color-black dis-mt-12">
                        {item.value}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </CommonModal>

      <CommonModal
        isOpen={isTypoTokenModalOpen}
        onRequestClose={() => setTypoTokenModalOpen(false)}
        title="Change token"
        onApply={() => handleApplyTypographyChange()}
        onCancelText="Cancel"
        onApplyText="Apply"
      >
        <div className="plt-modal-body-content">
          <div
            className="plt-preview  dis-mb-32"
            style={{ fontFamily: buttonState }}
          >
            <PrimaryButton
              style={{
                fontFamily: selectedToken?.data?.fontFamily?.value,
                fontSize: selectedToken?.data?.fontSize?.value,
                lineHeight: selectedToken?.data?.lineHeight?.value,
                fontWeight: selectedToken?.data?.fontWeight?.value,
              }}
              onClick={{}}
              text={"Button text"}
              className={`${selectedClassName} ${buttonState} $  d-flex m-auto`}
            />
          </div>
          <div className="modal-token-wrapper">
            <div className="token-list dss-custom-radio-group flex-column">
              {uiTextData.map((token) => {
                return (
                  <div key={token.id} className="token-item">
                    <label
                      className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default ${
                        isEqual(selectedToken?.value, token.data)
                          ? "checked"
                          : ""
                      }`}
                    >
                      <input
                        className="dss-radio dss-radio-input dss-radio-default"
                        name="radio-group"
                        type="radio"
                        id={token.id}
                        checked={isEqual(selectedToken?.value, token.data)}
                        onChange={() => handleTokenChange(token)}
                      />
                      <div className="dss-radio-label" htmlFor={token.id}>
                        <p className="plt-badge plt-body-sm">
                          {token.className}
                        </p>
                      </div>
                    </label>
                    <div className="token-info dis-ml-40">
                      <p className="plt-body-sm font-color-black dis-mt-12">
                        Size: {token.fontSize.value}
                      </p>
                      <p className="plt-body-sm">
                        <span className="plt-body-sm font-color-black">
                          Line height: {token.lineHeight.value}
                        </span>
                        <span className="plt-body-sm font-color-black dis-ml-32">
                          Weight: {token.fontWeight.value}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CommonModal>

      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewButtonComponent;
