import SubscriptionTableRaw from "./SubscriptionTableRaw";

const SubscriptionTable = ({ data }) => {
  return (
    <div className='plt-table-wrapper'>
      <div className='plt-table-container'>
        <table className='plt-table'>
          <thead>
            <tr>
              <th className='plt-table-head'>
                Invoice number
              </th>
              <th className='plt-table-head'>
                Date of issue
              </th>
              <th className='plt-table-head'>
                Amount paid
              </th>
              <th className='plt-table-head'>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <SubscriptionTableRaw data={data} />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscriptionTable;
