import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/scss/pages/DesignSystem.scss";
import { fetchDesignSystemById } from "../api";
import MainContent from "../components/DesignSystemPage/MainContent/MainContent";
import DesignSystemLayout from "../components/DesignSystemPage/DesignSystemLayout";
import { useGlobalContext } from "context/GlobalContext";
import { convertDefaultTokens } from "helpers";
import { loadGoogleFont, loadManualFont } from "helpers/font";
import Principles from "components/DesignSystemPage/Principles";

export const PrinciplesPage = () => {
  const { id } = useParams();
  const [designSystemName, setDesignSystemName] = useState("");
  const [page, setPage] = useState("");
  const [fonts, setFonts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [bannerImage, setBannerImage] = useState("");
  const { setLoading, setDesignSystem, setDesignSystemMeta } =
    useGlobalContext();

  useEffect(() => {
    const fetchDesignSystem = async () => {
      try {
        setLoading(true);
        const data = await fetchDesignSystemById(id);
        setDesignSystemMeta(data);
        setDesignSystemName(data.design_system_name);
        setPage(data.pages.find((page) => page.type === "gs"));
        setFonts(data.fonts);
        setBannerImage(data.banner_url);
        let tokens = convertDefaultTokens(data.tokens);
        setDesignSystem(tokens);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchDesignSystem();
    }
  }, [id]);
  useEffect(() => {
    fonts.map((font) => {
      if (font.type === "manual") {
        loadManualFont(font);
      } else {
        loadGoogleFont(font.family);
      }
    });
  }, [fonts]);

  const bannerData = {
    heading: designSystemName,
    description:
      "Foundations inform the basis of any great user interface, from accessibility standards to essential patterns for layout and interaction.",
    bannerImage: bannerImage,
  };

  const tabs = ["Principles"];
  return (
    <DesignSystemLayout
      designSystemName={designSystemName}
      bannerData={bannerData}
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      setBannerImage={setBannerImage}
    >
      {/* <MainContent page={page} /> */} 
      <div className='tab-content-wrapper d-flex justify-content-between'>
        {selectedTab === 0 && <Principles/>}
      </div>
    </DesignSystemLayout>
  );
};
