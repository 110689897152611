import { getUser } from "hooks/storageUtils";
import TeamTableRaw from "./TeamTableRaw";
import { useGlobalContext } from "context/GlobalContext";

const TeamTable = ({ data, roles, setTeams }) => {
  // const user = getUser();
  // const designSystems = user?.design_systems?.filter(
  //   (ds) => ds.admin_id === user.id
  // );
  const { designSystems } = useGlobalContext();
  return (
    <div className='plt-settings-table'>
      <div className='plt-table-wrapper'>
        {/* Team */}
        <div className='plt-table-container plt-table-tertiary-container'>
          <table className='plt-table plt-table-team'>
            <thead>
              <tr>
                <th className='plt-table-head'>#</th>
                <th className='plt-table-head'>Name</th>
                <th className='plt-table-head'>Role</th>
                <th className='plt-table-head'>Access</th>
                <th className='plt-table-head'>Status</th>
                <th className='plt-table-head'>Date added</th>
                <th className='plt-table-head'>Last active</th>
                <th className='plt-table-head'></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((team, index) => (
                <TeamTableRaw
                  key={team.id}
                  row={team}
                  slno={index + 1}
                  roles={roles}
                  setTeams={setTeams}
                  designSystems={designSystems}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TeamTable;
