import React, { useState, useRef, useEffect } from "react";
import { CardSettingsIcon } from "components/Icons";
import EditDesignsystemsModal from "./EditDesignsystemsModal";
import { toast } from "react-toastify";
import { useGlobalContext } from "context/GlobalContext";
import { updateMember } from "api";
import SelectInput from "components/SelectInput";
import { TYPE, ROLES } from "constants";
import AuthAction from "components/auth/AuthAction";
import EditButton from "components/Buttons/EditButton";
import Tooltip from "components/Tooltip";
import Permission from "components/auth/Permission";
import UserImage from "components/Common/UserImage";
import ChangeDomainNameModal from "./ChangeDomainNameModal";
import { updateDesignSystemDomainName } from "api";
import { deleteDesignSystem } from "api";
import ConfirmDialog from "components/Common/Confirm/ConfirmDialog";
import { updateDesignSystemType } from "api";
import { manageAccess } from "api";
import RenameModal from "components/Home/FileUpload/RenameModal";
import { renameDesignSystem } from "api";
import AddMemberModal from "./AddMemberModal";
import { designSystemManageAccess } from "api";

const DesignsystemsTableRaw = ({
  row,
  slno,
  designSystems,
  setDesignSystems,
}) => {
  const context = useGlobalContext();
  const { setLoading, designSystemMeta } = useGlobalContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedRole, setSelectedRole] = useState();
  const [isUrlModalOpen, setuRLModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [isPrivacyConfirmModalOpenOpen, setPrivacyConfirmModalOpenOpen] =
    useState(false);
  const [isDeleteConfirmModalOpenOpen, setDeleteConfirmModalOpenOpen] =
    useState(false);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isAddMemberModalOpen, setAddMemberModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useEffect(() => {
    setSelectedRole(row?.type);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const deleteState = (id) => {
    setDesignSystems((prevState) =>
      prevState.filter((designSystem) => designSystem.id !== id)
    );
  };

  const handleDeleteDesignSystem = async (id) => {
    try {
      setLoading(true);
      const data = await deleteDesignSystem(id);
      toast.success(data.message);
      deleteState(id);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to delete the design system", error);
    } finally {
      setLoading(false);
    }
  };

  const updateState = (data) => {
    setDesignSystems((prevState) =>
      prevState.map((designSystem) => {
        if (designSystem.id !== data.design_system_id) {
          return designSystem;
        } else {
          return {
            ...designSystem,
            domain: { domain_name: data.domain_name },
          };
        }
      })
    );
  };
  const updateRenameState = (data) => {
    setDesignSystems((prevState) =>
      prevState.map((designSystem) => {
        if (designSystem.id !== data.id) {
          return designSystem;
        } else {
          return {
            ...designSystem,
            design_system_name: data.design_system_name,
          };
        }
      })
    );
    //update global context
    context.setDesignSystems((prevState) =>
      prevState.map((designSystem) => {
        if (designSystem.id !== data.id) {
          return designSystem;
        } else {
          return {
            ...designSystem,
            design_system_name: data.design_system_name,
          };
        }
      })
    );
  };
  const updateMamanageAccessState = (data) => {
    setDesignSystems((prevState) =>
      prevState.map((designSystem) => {
        if (designSystem.id !== data.id) {
          return designSystem;
        } else {
          return {
            ...designSystem,
            data,
          };
        }
      })
    );
  };
  const handleManageAccess = async (input, designSystemId) => {
    try {
      closeModal();
      setLoading(true);
      const data = await manageAccess(input, designSystemId);
      updateMamanageAccessState(data.data);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to update memeber", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeDomainName = async (id, name) => {
    try {
      setLoading(true);
      const { data } = await updateDesignSystemDomainName(id, name);
      updateState(data);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to rename domain", error);
    } finally {
      setLoading(false);
    }
  };

  const openUrlModal = () => {
    setuRLModalOpen(true);
  };

  const UrlCloseModal = () => {
    setuRLModalOpen(false);
  };

  const changePrivacy = (value) => {
    setSelectedType(value.value);
    setPrivacyConfirmModalOpenOpen(true);
  };

  const closePrivacyConfirmModal = () => {
    setPrivacyConfirmModalOpenOpen(false);
  };

  const handleChangePrivacy = async () => {
    try {
      setLoading(true);
      const { data } = await updateDesignSystemType(row?.id, {
        type: selectedType,
      });
      updateState(data);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to update memeber", error);
    } finally {
      setLoading(false);
      closePrivacyConfirmModal();
    }
  };

  const closeDeleteConfirmModal = () => {
    setDeleteConfirmModalOpenOpen(false);
  };
  const closeRenameModal = () => {
    setRenameModalOpen(false);
  };

  const handleRenameApply = async (name) => {
    if (!name) {
      toast.warning("Please enter name");
      return;
    }
    try {
      setLoading(true);
      const { data } = await renameDesignSystem(row.id, {
        design_system_name: name,
      });
      toast.success(data.message);
      updateRenameState(data);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to rename the design system", error);
    } finally {
      setLoading(false);
    }
    closeRenameModal();
  };

  const closeAddMemberModal = () => {
    setAddMemberModalOpen(false);
  };

  const handleDesignSystemManageAccess = async (input, designSystemId) => {
    try {
      closeModal();
      setLoading(true);
      const data = await designSystemManageAccess(input, designSystemId);
      updateMamanageAccessState(data.data);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to update memeber", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnAddMember = (flag) => {
    setAddMemberModalOpen(flag);
    setSelectedData(row);
  };

  const url = process.env.REACT_APP_SHEET_URL;

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center plt-body-md'>
          {row?.design_system_name}
        </div>
      </td>
      <td>
        <div className='plt-role-wrapper d-flex plt-body-md plt-font-color-secondary'>
          <a
            href={`/${row.id}/overview/getting-started`}
            className='plt-role-description plt-body-md plt-font-color-secondary'
          >
            {row?.domain?.domain_name
              ? `${row.domain.domain_name}.dessign.systems`
              : ""}
          </a>
          <AuthAction>
            <Permission designSystem={designSystemMeta}>
              <span className='dis-ml-16' onClick={openUrlModal}>
                <Tooltip
                  className={"plt"}
                  position='top'
                  arrow='btm-center'
                  text='Edit URL'
                >
                  <EditButton />
                </Tooltip>
              </span>
            </Permission>
          </AuthAction>
        </div>
      </td>
      <td>
        <div className='plt-asccess-description  plt-body-md plt-font-color-secondary'>
          <span
            onClick={() => setModalOpen(true)}
            className='plt-modal-link plt-ui-text-sm-medium cursor-pointer d-block w-fit'
          >
            {row?.users_count} user(s)
          </span>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center plt-body-md'>
          <span className='dis-mr-12'>
            <UserImage user={row?.admin} />
          </span>
          {row?.admin?.name}
        </div>
      </td>
      <td>
        <div className='privacy-dropdown'>
          <SelectInput
            options={TYPE}
            selectedOption={selectedRole ?? "Select"}
            defaultValue={TYPE.find((t) => t.value === row?.type)}
            onSelect={(selectedValue) => changePrivacy(selectedValue)}
          />
        </div>
      </td>
      <td>
        <div className='menu cursor-pointer'>
          <div
            className='plt-select-dropdown-wrapper plt-filter-dropdown'
            ref={dropdownRef}
          >
            <span onClick={() => setIsDropdownOpen((prev) => !prev)}>
              <CardSettingsIcon />
            </span>

            {isDropdownOpen && (
              <div className='user-dropdown'>
                <ul>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => {
                      setAddMemberModalOpen(true);
                      setSelectedData(row);
                    }}
                  >
                    Add members
                  </li>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => {
                      setRenameModalOpen(true);
                      setSelectedData(row);
                    }}
                  >
                    Rename
                  </li>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => setDeleteConfirmModalOpenOpen(true)}
                  >
                    Delete
                  </li>
                </ul>
              </div>
            )}
          </div>
          <EditDesignsystemsModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            onApply={handleManageAccess}
            title='Manage access'
            data={row}
            onAddMember={handleOnAddMember}
          />
          <ConfirmDialog
            footerClassName='plt'
            isOpen={isPrivacyConfirmModalOpenOpen}
            onRequestClose={closePrivacyConfirmModal}
            onApply={handleChangePrivacy}
            onApplyText='Change'
            title='Change design system privacy?'
            message='Are you sure to change privacy.'
          />
          <ConfirmDialog
            footerClassName='plt'
            isOpen={isDeleteConfirmModalOpenOpen}
            onRequestClose={closeDeleteConfirmModal}
            onApply={() => handleDeleteDesignSystem(row?.id)}
            onApplyText='Delete'
            title='Are you sure?'
            message='Are you sure to delete the design system.'
          />
          <ChangeDomainNameModal
            isOpen={isUrlModalOpen}
            onRequestClose={UrlCloseModal}
            onApply={handleChangeDomainName}
            title='Edit URL'
            data={row}
          />
          <RenameModal
            isOpen={isRenameModalOpen}
            onRequestClose={closeRenameModal}
            onApply={handleRenameApply}
            title='Rename design system'
            data={row}
          />
          <AddMemberModal
            isOpen={isAddMemberModalOpen}
            onRequestClose={closeAddMemberModal}
            onApply={handleDesignSystemManageAccess}
            title='Add members'
            data={row}
          />
        </div>
      </td>
    </tr>
  );
};

export default DesignsystemsTableRaw;
