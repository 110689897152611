import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import AvatarGroupDetail from "../../../../assets/images/png/AvatarGroupDetail.png";
// import alertInformative from "../../../../assets/images/png/alertInformative.png";
// import alertCaution from "../../../../assets/images/png/alertCaution.png";
// import alertSuccess from "../../../../assets/images/png/alertSuccess.png";
// import alertDanger from "../../../../assets/images/png/alertDanger.png";
// import alertPlacement from "../../../../assets/images/png/alertPlacement.png";
// import alertSpacing from "../../../../assets/images/png/alertSpacing.png";
// import { AlertUsagePrimaryDatas } from "../../../../assets/data/data";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";
import GuidelineCardAvatarGroup from "components/Common/GuidelineCardAvatarGroup";

const details = [
  { number: 1, title: 'Body', description: 'The picture representing the user or entity.' },
  { number: 2, title: 'Status', description: 'A symbol indicating the avatar\'s status, positioned in the top-right corner of the avatar component.' },
 
];


const UsageAvatarGroupComponent = () => {
  const { isLoading } = useGlobalContext();
  const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];
  // const [selectedTab, setSelectedTab] = useState(0);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            The avatar component consists of three elements: Body, status, and presence.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={AvatarGroupDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map(detail => (
              <DetailItem  
                key={detail.number} 
                number={detail.number} 
                title={detail.title} 
                description={detail.description} 
              />
            ))}
          </ul>
          
      
            <div className="dis-mt-40 dis-mb-40">
              <div className="plt-content-wrapper dis-mb-24">
                <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                Accessibility
                </h6>
                <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Use the name prop to include alternative text for screen readers.
                </p>
              </div>
            </div>

            <div className="plt-content-wrapper dis-mb-24">
            <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
            Guidelines
            </h6>
            <ul className="plt-detail-list dis-mt-12">
              <li className="plt-body-md plt-font-color-primary">Position labels to the left of dropdown fields, aligning with the top edge for clarity.</li>
              <li className="plt-body-md plt-font-color-primary">Maintain consistent spacing between labels and dropdown fields to ensure balance and readability.</li>
              <li className="plt-body-md plt-font-color-primary">Align labels with the top of the dropdown fields for a clean and organized look.</li>
              <li className="plt-body-md plt-font-color-primary">Ensure labels are clearly linked to their corresponding dropdown fields to enhance usability.</li>
            </ul>
          </div>
         

          <GuidelineCardAvatarGroup />
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageAvatarGroupComponent;
