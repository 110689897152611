import React, { useState } from "react";
import "./PreviewAlertComponent.scss";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../Common/ComponentCard";
import "react-loading-skeleton/dist/skeleton.css";
import { CloseIcon, ButtonIcon } from "../../../Icons";
import CommonModal from "../../../Common/CommonModal";
import { EditIcon } from "components/Icons";
import { useGlobalContext } from "context/GlobalContext";
import colorSectionData from "../../../../pages/styles/colorSectionData";
import ColorPickerComponent from "../../ColorPickerComponent";
import { addToLocalStorage } from "hooks/storageUtils";
import useScrollManagement from "hooks/useScrollManagement";

const AlertDisplay = ({
  prefix,
  closable,
  // action,
  actionFooter,
  onClose,
  alerttype,
  title,
  description = "This is an info alert. It also has longer text to see what these alerts can look like when broken into multiple lines. This one will definitely break into multiple lines in most standard screen resolutions.",

}) => (
  <div className={`dss-alert dss-${alerttype}`}>
    <div className='dss-alert-icon'>
      {prefix && <ButtonIcon color='#171717' />}
    </div>
    <div
      className={`dss-alert-msg-container dis-ml-4 ${actionFooter ? "dss-alert-action-footer" : ""
        }`}
    >
      <h6 className='dss-alert-msg dss-heading-xs'>{title}</h6>
      <p className='dss-body-strong-sm-medium dss-alert-msg '>
        {description}
      </p>
    </div>
    <div className='d-flex dss-alert-btn-group'>
      <button className='dss-btn dss-btn-primary dss-btn-sm ' type='button'>
        Button
      </button>
      <button className='dss-btn dss-btn-outlined dss-btn-sm ' type='button'>
        Button
      </button>
    </div>
    {closable && (
      <span onClick={onClose} className='dss-alert-close-icon'>
        <CloseIcon />
      </span>
    )}
  </div>
);

const PreviewAlertComponents = ({
  tabData,
  setSelectedTab,
}) => {

  const ColorSectionComponent = ({
    title,
    description,
    colorData,
    type,
    setTokenState,
    buttonState,
    tokenState,
    initialColor,
  }) => {

    return (
      <div className="plt-content-wrapper dis-mb-32">
        {title && type?.length && (
          <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-12">
            {title}
          </h6>
        )}
        {description && !type?.length && (
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-24">
            {description}
          </p>
        )}

        {colorData.map((color, index) => (
          <>
            {color?.subTitle && (
              <p className="plt-subtitle plt-heading-xs plt-font-color-primary dis-mb-8">
                {color?.subTitle}
              </p>
            )}
            <ColorPickerComponent
              key={index}
              initialColor={initialColor}
              // buttonState={buttonState}
              setTokenState={setTokenState}
              type={type}
              tokenState={tokenState}
              baseColor={color.baseColor}
              id={color.id}
              shades={color.shades}
              tokenType={color.tokenType}
            />
          </>
        ))}
      </div>
    );
  };
  const [showAlertConfig, setShowAlertConfig] = useState(null);
  const [selectedTokenData, setSelectedTokenData] = useState({});
  const [tokenType, setTokenType] = useState("");
  const [isColorTokenModalOpen, setColorTokenModalOpen] = useState(false);
  const colorSections = colorSectionData();
  const [tokenState, setTokenState] = useState({ key: "", value: "" });

  const handleButtonClick = (config) => {
    setShowAlertConfig(config);
    setTimeout(() => {
      setShowAlertConfig(null);
    }, 5000);
  };

  const onApplyChanges = async () => {
    addToLocalStorage([tokenState]);
    setColorTokenModalOpen(false);
  };


  const AllUsage = () => (
    <p className='plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32'>
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className='plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer'
      >
        usage
      </span>
    </p>
  );

  const commonDesc =
    "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container.";
  const alertConfigurations = [
    {
      title: "Informative",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
      alerttype: "alert-informative",
    },
    {
      title: "Caution",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
      alerttype: "alert-caution",
    },
    {
      title: "Success",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
      alerttype: "alert-success",
    },
    {
      title: "Danger",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
      alerttype: "alert-danger",
    },
  ];

  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedAlertData, setSelectedAlert] = useState(false);
  const {
    designSystem,
  } = useGlobalContext();

  const showModal = (selectedAlert) => {
    setModalVisibility(true);
    setSelectedAlert(selectedAlert)
  };

  const [activeSideTab, setActiveSideTab] = useState(0);
  const sectionRefs = alertConfigurations?.map(() => React.useRef(null));

  const scrollToSection = useScrollManagement(
    sectionRefs,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className='tab-content-wrapper d-flex justify-content-between'>
      <div className='tab-content-container dis-pr-48 dis-pl-48'>
        {alertConfigurations.map((config, index) => (
          <div key={config.title} ref={sectionRefs[index]} className='plt-content-wrapper'>
            <div className='plt-content-wrapper-block dis-mb-24'>
              <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
                {config.title}
              </h6>
              <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                {commonDesc}
              </p>
            </div>
            <ComponentCard editClick={() => showModal(config)} edit={true}>
              <AlertDisplay
                prefix={config?.prefix}
                closable={config?.closable}
                action={config?.action}
                actionFooter={config?.actionFooter}
                alerttype={config.alerttype}
                title={config.title}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}

        {showAlertConfig && (
          <AlertDisplay
            prefix={showAlertConfig.prefix}
            closable={showAlertConfig.closable}
            action={showAlertConfig.action}
            actionFooter={showAlertConfig.actionFooter}
            onClose={() => setShowAlertConfig(null)}
          />
        )}
      </div>

      {isModalVisible && selectedAlertData && <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => {
          setModalVisibility(false);
        }}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">

              </div>

            </div>
          </div>
          <div
            className="plt-preview dis-mb-32 d-flex justify-content-center"
          >
            <AlertDisplay
              prefix={selectedAlertData?.prefix}
              closable={selectedAlertData?.closable}
              action={selectedAlertData?.action}
              actionFooter={selectedAlertData?.actionFooter}
              alerttype={selectedAlertData.alerttype}
              title={selectedAlertData.title}
              description="sample test..."

            />
          </div>
          <>
            <div className="plt-token-details dis-mb-32">
              <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                Background color
              </h6>
              <div className="plt-token-value-item d-flex">
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  {designSystem?.light?.background?.alert?.fill[[selectedAlertData?.title?.toLowerCase()]]?.tokenName
                  }
                </p>
                <span
                  className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                  onClick={() => {
                    setSelectedTokenData(designSystem?.light?.background?.alert?.fill[selectedAlertData?.title?.toLowerCase()])
                    setTokenType("inputBg");
                    setColorTokenModalOpen(true);
                  }}
                >
                  <EditIcon />
                </span>
              </div>
            </div>
            <div className="plt-token-details dis-mb-32">
              <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
                Text color
              </h6>
              <div className="plt-token-value-item d-flex">
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  {designSystem?.light?.text?.alert[selectedAlertData?.title?.toLowerCase()]?.tokenName}
                </p>
                <span
                  className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                  onClick={() => {
                    setSelectedTokenData(designSystem?.light?.text?.alert[selectedAlertData?.title?.toLowerCase()]);
                    setTokenType("inputBg");
                    setColorTokenModalOpen(true);
                  }}
                >
                  <EditIcon />
                </span>
              </div>
            </div>
          </>
        </div>
      </CommonModal>
      }

      {/* color modal */}
      <CommonModal
        isOpen={isColorTokenModalOpen}
        onRequestClose={() => {
          setColorTokenModalOpen(false);
        }}
        title="Change token"
        onApply={onApplyChanges}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="change-token-modal"
      >
        <>
          <div className="plt-preview dis-mb-24">
            <AlertDisplay
              prefix={selectedAlertData?.prefix}
              closable={selectedAlertData?.closable}
              action={selectedAlertData?.action}
              actionFooter={selectedAlertData?.actionFooter}
              alerttype={selectedAlertData.alerttype}
              title={selectedAlertData.title}
              description="sample test..."

            />
          </div>
          <div className="plt-modal-body-content dis-mt-32 dis-pl-24 dis-pr-24">
            {colorSections
              .filter(section => section.title === "Semantic color")
              .map((section, index) => (
                <div key={index}>
                  <ColorSectionComponent
                    {...section}
                    onApplyChanges={onApplyChanges}
                    type={tokenType}
                    tokenState={selectedTokenData}
                    setTokenState={setTokenState}
                  />
                </div>
              ))}

          </div>
        </>
      </CommonModal>
      <div className='plt-token-contents'>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewAlertComponents;
