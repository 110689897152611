import SuperAdminLayout from "components/layouts/SuperAdminLayout";
import React, { useEffect, useState } from "react";
import InvoiceTable from "./InvoiceTable";
import { fetchInvoices } from "api/superAdmin";
import { useGlobalContext } from "context/GlobalContext";

const Invoice = () => {
  const { setLoading } = useGlobalContext();
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const getInvoices = async (userId) => {
      try {
        setLoading(true);
        const { data } = await fetchInvoices(userId);
        setInvoices(data);
      } catch (error) {
        console.error("Failed to fetch invoices", error);
      } finally {
        setLoading(false);
      }
    };

    getInvoices();
  }, []);
  return (
    <SuperAdminLayout>
      <div className='tab-content-wrapper'>
        <div className='tab-content-container dis-pr-48 dis-pl-48'>
          <div className={`plt-content-block dis-mb-32 w-100`}>
            <div className='plt-content-wrapper dis-mb-40 d-flex justify-content-between align-items-center'>
              <div className='plt-content-block-top'>
                <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
                  Invoices
                </h6>
              </div>
            </div>
            <InvoiceTable data={invoices} setInvoices={setInvoices} />
          </div>
        </div>
      </div>
    </SuperAdminLayout>
  );
};

export default Invoice;
