import React, { useState } from "react";
import "../../assets/scss/pages/_DesignSystemComponents.scss";
import DesignSystemLayout from "../../components/DesignSystemPage/DesignSystemLayout";
import { useParams } from "react-router-dom";
import { getComponentsSideData } from "../../assets/data/sideTabData";
import PreviewAvatarGroupComponent from "../../components/DesignSystemPage/AvatarGroupComponent/PreviewAvatarComponent";
import { useGlobalContext } from "context/GlobalContext";
import UsageAvatarGroupComponent from "components/DesignSystemPage/AvatarGroupComponent/UsageAvatarGroupComponent";

const AvatarGroupComponents = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();

  const tabData = [
    "Default",
    // "Secondary colors",
    // "Supporting colors",
    // "Neutral colors",
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const bannerData = {
    heading: "Avatar Group",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PreviewAvatarGroupComponent
            tabData={tabData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setSelectedTab={setSelectedTab}
          />
        );
      // return <div>content1</div>;
      case 1:
        return (
          <div>
           <UsageAvatarGroupComponent/>
          </div>
        );
      case 2:
        return <div>content3</div>;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title='Components'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default AvatarGroupComponents;
