import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import emptyDetail from "../../../../assets/images/png/emptyDetail.png";
import emptyPlacement from "../../../../assets/images/png/emptyPlacement.png";
import emptySpacing from "../../../../assets/images/png/emptySpacing.png";
import useScrollManagement from "hooks/useScrollManagement";
import './UsageEmptystateComponent.scss';
import DetailItem from "components/DetailItem";
import GuidelineCardEmpty from "components/Common/GuidelineCardEmpty";

const details = [
  { number: 1, title: 'Illustration (Optional)', description: 'A visual aid that supports the message, enhancing clarity and context.' },
  { number: 2, title: 'Header', description: 'A clear, brief title that summarizes the current situation or state.' },
  { number: 3, title: 'Buttons (Optional)', description: 'Actionable options to continue, dismiss, or respond to the message.' },
  { number: 4, title: 'Description (Optional)', description: 'A concise explanation of the issue with steps for resolution.' },

];


const UsageEmptystateComponent = () => {
  const { isLoading } = useGlobalContext();
  const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];
  // const [selectedTab, setSelectedTab] = useState(0);

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            The text input component includes a label and an input field, available in two types: Default, with a fixed width for consistency, and Fluid, which expands to fit available space for adaptability across layouts.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={emptyDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map(detail => (
              <DetailItem  
                key={detail.number} 
                number={detail.number} 
                title={detail.title} 
                description={detail.description} 
              />
            ))}
          </ul>

            <div className="dis-mt-40 dis-mb-40">
              <div className="plt-content-wrapper dis-mb-24">
                <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                Placement
                </h6>
                <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Ensure the text field is left-aligned and properly indented. This alignment promotes consistency and enhances readability across the user interface.
                </p>
              </div>
              
              <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                <img src={emptyPlacement} alt="userimage" />
              </div>


              <div className="plt-content-wrapper dis-mb-24 dis-mt-24">
                <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                Spacing
                </h6>
                <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Maintain consistent spacing around the text field to ensure it is visually distinct and easily accessible. Adequate spacing improves readability and prevents a cluttered interface.
                </p>
              </div>

              <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                <img src={emptySpacing} alt="userimage" />
              </div>

            </div>

            <div className="plt-content-wrapper dis-mb-24">
            <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
            Guidelines
            </h6>
            <ul className="plt-detail-list dis-mt-12">
              <li className="plt-body-md plt-font-color-primary">Position labels to the left of text input fields, aligning them with the top of the fields.</li>
              <li className="plt-body-md plt-font-color-primary">Maintain uniform spacing between labels and input fields to ensure clarity and balance.</li>
              <li className="plt-body-md plt-font-color-primary">Align labels with the top edge of the text input fields for a clean and organized appearance.</li>
              <li className="plt-body-md plt-font-color-primary">Ensure labels are clearly associated with their corresponding input fields for improved usability.</li>
            </ul>
          </div>
         

          <GuidelineCardEmpty />
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageEmptystateComponent;
