import React from "react";
import PlanTableRow from "./PlanTableRow";

const PlanTable = ({ data, setPlans }) => {
  return (
    <div className='plt-settings-table'>
      <div className='plt-table-wrapper'>
        {/* Team */}
        <div className='plt-table-container plt-table-tertiary-container'>
          <table className='plt-table plt-table-team'>
            <thead>
              <tr>
                <th className='plt-table-head'>#</th>
                <th className='plt-table-head'>Plan</th>
                <th className='plt-table-head'>Price</th>
                <th className='plt-table-head'>Features</th>
                <th className='plt-table-head'></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((plan, index) => (
                <PlanTableRow
                  key={plan.id}
                  row={plan}
                  slno={index + 1}
                  setPlans={setPlans}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PlanTable;
